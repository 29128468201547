import { AuthProvider, AuthSplash, ControleAcessoProvider } from "@omnijus/common";
import { CLIENT_ID } from "lib/http/client-id";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { Router } from "router";
import { EscritorioProvider, useEscritorio } from "selecionar-escritorio/escritorio-context";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const configuration = {
    client_id: "spa-escritorio",
    redirect_uri: window.location.origin + "/authentication/callback",
    response_type: "code",
    post_logout_redirect_uri: window.location.origin,
    scope: "openid profile email api_bff_escritorio",
    authority: process.env.REACT_APP_AUTH_URL,
    silent_redirect_uri: window.location.origin + "/authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: true,
};

const AppWithEscritorio = () => {
    const { escritorio } = useEscritorio();
    return (
        <ControleAcessoProvider
            clientId={CLIENT_ID}
            baseUrl={process.env.REACT_APP_BFF_ESCRITORIO_API_URL!}
            idEscritorio={escritorio?.id}
        >
            <Router />
        </ControleAcessoProvider>
    );
};

const App = () => {
    return (
        <AuthProvider userManagerSettings={configuration} callbackComponent={<AuthSplash />}>
            <EscritorioProvider>
                <AppWithEscritorio />
            </EscritorioProvider>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </AuthProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
