import { RequestApi } from "@omnijus/common";
import { Movimento } from "../agendamento/models/movimento";
import { EscritorioRequestApi } from "../escritorio-request-api";
import { DetalheProcesso } from "./models/detalhe-processo";
import { ProcessoDocumentoViewModel } from "./models/processo-documento";
import { ProcessoParametros } from "./models/processo-parametros";

const processoDocumentoUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL +
  "/api/Processo/v1/ProcessoDocumento";
const processoDetalheUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL +
  "/api/Processo/v1/ProcessoDetalhe";
const processoUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/Processo/v1/Processo";
const filtroUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/Dominio/v1/Filtro";

export const ProcessoService = {
  downloadDocumento: async (idDocumento: string, filename: string) => {
    const url = `${processoDocumentoUrl}/${idDocumento}/Download`;
    return await EscritorioRequestApi.download(url, filename);
  },
  obterDetalhesProcesso: async (numeroProcesso: string) => {
    const url = `${processoDetalheUrl}/${numeroProcesso}`;

    return await EscritorioRequestApi.get<DetalheProcesso>(
      url,
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  obterMovimentos: async (
    idProcesso: string,
    apenasComDocumentos: boolean = false
  ) => {
    const url = new URL(`${processoDetalheUrl}/${idProcesso}/Movimentos`);

    url.search = RequestApi.objectToQueryString({ apenasComDocumentos });

    return await EscritorioRequestApi.get<Movimento[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  downloadArquivosMovimento: async (
    idProcesso: string,
    idProcessoMovimento: string
  ) => {
    const url = `${processoDetalheUrl}/${idProcesso}/Movimento/${idProcessoMovimento}/Download`;

    return await EscritorioRequestApi.download(url, "documentos_movimento.zip");
  },
  listarDocumentosDoProcesso: async (idProcesso: string) => {
    const url = `${processoDetalheUrl}/${idProcesso}/ListarDocumentosDoProcesso`;

    return await EscritorioRequestApi.get<ProcessoDocumentoViewModel[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  obterMovimento: async (idProcesso: string, idProcessoMovimento: string) => {
    const url = `${processoDetalheUrl}/${idProcesso}/Movimento/${idProcessoMovimento}`;

    return await EscritorioRequestApi.get<Movimento>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  obterComboMovimentos: async (
    idProcesso: string,
    idProcessoMovimento?: string
  ) => {
    const url = `${processoDetalheUrl}/${idProcesso}/ObterComboMovimentos${
      idProcessoMovimento ? `/${idProcessoMovimento}` : ""
    }`;

    return await EscritorioRequestApi.get<{ id: string; value: string }[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  listarProcessos: async (filtro?: ProcessoParametros | URLSearchParams) => {
    const url = new URL(`${processoUrl}/ListarDetalheProcesso`);

    if (filtro) {
      if (filtro instanceof URLSearchParams) {
        url.search = filtro.toString();
      } else {
        url.search = RequestApi.objectToQueryString(filtro);
      }
    }

    return await EscritorioRequestApi.get<DetalheProcesso[]>(url.toString());
  },
  listarFase: async (idRito: number) => {
    let url = new URL(`${filtroUrl}/ListarFaseProcessoPorIdRitoProcesso`);

    url.searchParams.append("idRitoProcesso", idRito.toString());

    return await EscritorioRequestApi.get<{ id: number; value: string }[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
  listarMomento: async (idRito: number, idFase: number) => {
    let url = new URL(
      `${processoUrl}/ListarMomentoProcessoPorIdRitoProcessoIdFaseProcesso`
    );

    url.searchParams.append("idRitoProcesso", idRito.toString());
    url.searchParams.append("idFaseProcesso", idFase.toString());

    return await EscritorioRequestApi.get<{ id: number; value: string }[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
};
