import React, { useMemo, useRef } from "react";
import { FiltroAplicado } from "./filtros-aplicados";

interface DefaultContext {
    getValues: () => Array<FiltroAplicado>;
    onChangeFiltroAplicado: (novoFiltro: FiltroAplicado) => void;
}

interface FiltroAplicadoProviderProps {
    children: JSX.Element;
}

const FiltrosAplicadosContext = React.createContext<DefaultContext | undefined>(undefined);

export const FiltrosAplicadosProvider = (props: FiltroAplicadoProviderProps) => {
    let value = useRef([] as Array<FiltroAplicado>);

    return useMemo(
        () => (
            <FiltrosAplicadosContext.Provider
                value={{
                    getValues: () => [...value.current],
                    onChangeFiltroAplicado: (novoFiltro: FiltroAplicado) => {
                        value.current.unshift(novoFiltro);
                        value.current = value.current.filter(
                            (v, i, a) => a.findIndex((t) => t.label === v.label) === i && v.value
                        );
                    },
                }}
            >
                {props.children}
            </FiltrosAplicadosContext.Provider>
        ),
        [props.children]
    );
};

export const useFiltroAplicado = () => {
    let result = useRef({
        getValues: () => [] as Array<FiltroAplicado>,
        onChangeFiltroAplicado: (novoFiltro: FiltroAplicado) => {},
    });
    let context = React.useContext(FiltrosAplicadosContext);

    if (context) {
        result.current = context;
    }

    return useMemo(() => result.current, []);
};
