import { ActionFeedback, Back, Loading } from "@omnijus/common";
import { Formik } from "formik";
import { UsuarioCommand } from "lib/http/configuracoes/usuario/usuario-command";
import { UsuarioService } from "lib/http/configuracoes/usuario/usuario-service";
import { UsuarioViewModel } from "lib/http/configuracoes/usuario/usuario-viewmodel";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SpacerV } from "shared/layout/spacer";
import { object as YupObject, string as YupString } from "yup";
import { FormUsuario } from "./components/form-usuario";
import { UsuarioPerfis } from "./components/usuario-perfis";

const validationSchema = YupObject().shape({
  nome: YupString()
    .required("É necessário informar o nome")
    .min(3, "O nome deve ter no mínimo 4 caracteres"),
  email: YupString()
    .required("É necessário informar o email")
    .email("O E-mail informado está inválido"),
  cpf: YupString().required("É necessário informar o CPF"),
  idPerfil: YupString().required("É necessário informar pelo menos um perfil"),
});

interface LocationState {
  cpf: string;
}

export const Usuario = () => {
  const { id } = useParams<{ id: string }>();

  const location = useLocation();

  const [promiseUsuario, setPromiseUsuario] =
    useState<Promise<UsuarioViewModel | undefined>>();
  const [isNew, setIsNew] = useState<Boolean | undefined>();
  const [stateCpf, setCpf] = useState<LocationState | undefined>();

  const history = useHistory();

  useEffect(() => {
    if (id) {
      setIsNew(false);
      setPromiseUsuario(
        UsuarioService.Obter(id).then((usuario) => {
          if (!usuario) {
            return Promise.reject("Usuário não encontrado");
          }

          return {
            id: usuario?.id,
            nome: usuario?.nome,
            email: usuario?.email,
            cpf: usuario?.cpf,
            perfis: usuario?.perfis,
          } as UsuarioViewModel;
        })
      );
    } else {
      let state = location.state as LocationState;
      setCpf(state);
      setIsNew(true);
      setPromiseUsuario(
        Promise.resolve({
          nome: "",
          email: "",
          cpf: state.cpf,
        } as UsuarioViewModel)
      );
    }
  }, [id, location]);

  return (
    <>
      <Loading promise={promiseUsuario}>
        {(usuario) => {
          if (usuario) {
            usuario.cpf = (isNew ? stateCpf?.cpf : usuario?.cpf) ?? "";
          } else {
            usuario = {
              cpf: stateCpf?.cpf,
            } as UsuarioViewModel;
          }

          return usuario ? (
            <>
              <div className="margin-bottom">
                <Back />
              </div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{ ...usuario }}
                onSubmit={async (values) => {
                  let usuario = {
                    id: values.id,
                    cpf: values.cpf,
                    email: values.email,
                    nome: values.nome,
                  } as UsuarioCommand;

                  let result = await ActionFeedback.processing({
                    title: `Atualizando usuário...`,
                    execution: isNew
                      ? UsuarioService.Salvar(usuario)
                      : UsuarioService.Atualizar(usuario),
                  });

                  if (result?.id) {
                    history.replace(
                      `/configuracoes/usuario/editar/${result?.id}`
                    );
                  }
                }}
              >
                <FormUsuario isNew={isNew || false} action={() => null} />
              </Formik>
            </>
          ) : (
            <>Erro carregando usuário</>
          );
        }}
      </Loading>

      <SpacerV />

      <Loading promise={promiseUsuario}>
        {(usuario) => {
          if (!usuario) {
            return;
          }

          return (
            <>
              <UsuarioPerfis idUsuario={usuario.id} />
            </>
          );
        }}
      </Loading>
    </>
  );
};
