import classNames from "classnames";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import styles from "../field.module.scss";

interface Mask {
    mask: string;
    case?: "uppercase" | "lowercase" | "normal";
    charType?: "numeric" | "alphabet" | "alphanumeric";
}

const maskString = (original: string, masks: Mask[]) => {
    let mask: Mask | undefined = undefined; // Máscara a ser utilizada
    let masked = ""; // String com a máscara aplicada
    let i = 0, // Contador para a string originalFiltered
        j = 0; // Contador para a máscara
    const getLengthHashtag = (m: string) => (m.match(/#/g) || []).length; // Conta a quantidade de caracter '#' em uma string
    const getRegex = (charType?: "numeric" | "alphabet" | "alphanumeric") => {
        switch (charType) {
            case "numeric":
                return /[^0-9]+/gi;
            case "alphabet":
                return /[^A-Z]+/gi;
            case "alphanumeric":
                return /[^0-9A-Z]+/gi;
            default:
                return /[^0-9A-Z]+/gi;
        }
    };

    // Decidir qual máscara será aplicada de acordo com a quantidade de caracteres
    for (let auxMask of masks) {
        const regex = getRegex(auxMask.charType);
        const originalFiltered = original.replace(regex, "");

        if (
            originalFiltered.length <= getLengthHashtag(auxMask.mask) &&
            originalFiltered.length > getLengthHashtag(mask?.mask || "")
        ) {
            mask = auxMask;
        }
    }

    // Se nenhuma máscara encaixa na string, pegar a que menos desperdiça caracteres
    if (!mask) {
        mask = masks[0];
        for (let auxMask of masks) {
            const regex = getRegex(auxMask.charType);
            const originalFiltered = original.replace(regex, "");

            if (
                originalFiltered.length - getLengthHashtag(auxMask.mask) <
                originalFiltered.length - getLengthHashtag(mask?.mask || "")
            ) {
                mask = auxMask;
            }
        }
    }

    // Capturar apenas os caracteres que cabem na máscara
    const regex = getRegex(mask.charType);
    let originalFiltered = original.replace(regex, "");
    originalFiltered = originalFiltered.substr(0, getLengthHashtag(mask.mask));

    // Aplicar a máscara
    while (i < originalFiltered.length) {
        if (mask.mask[j] === "#") {
            masked += originalFiltered[i];
            i++;
        } else {
            masked += mask.mask[j];
        }

        j++;
    }

    switch (mask.case) {
        case "lowercase":
            masked = masked.toLowerCase();
            break;
        case "uppercase":
            masked = masked.toUpperCase();
            break;
        default:
            break;
    }

    return masked;
};

export interface OmnijusTextFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    /**
     * A máscara é uma string que representa como os caracteres alfanuméricos devem ser formatados
     * O caracter # é reservado e representa onde deve ser substituido por caracteres
     * Exemplo de máscara para CPF: ###.###.###-##
     */
    masks?: Mask[];
    onChange?: (e: React.ChangeEvent<any>) => void;
    className?: string;
}

export const OmnijusTextField = (props: OmnijusTextFieldProps) => {
    const { setFieldValue } = useFormikContext();

    return (
        <Field name={props.name} id={props.name} placeholder={props.placeholder}>
            {({ field, meta }: FieldProps) => {
                const value = meta.value !== undefined && meta.value !== null ? String(meta.value) : "";

                return (
                    <div className={classNames([styles["field-control"], props.className])}>
                        {props.label ? (
                            <label className={styles["label"]} htmlFor={props.name}>
                                {props.label}
                            </label>
                        ) : null}
                        <input
                            placeholder={props.placeholder}
                            {...field}
                            value={
                                props.masks && value ? maskString(value, props.masks).trim() : value || ""
                            }
                            onChange={(e) => {
                                e.target.value =
                                    props.masks && e.target.value
                                        ? maskString(e.target.value, props.masks).trim()
                                        : e.target.value || "";
                                field.onChange(e);
                                setFieldValue(props.name, e.target.value || "");
                            }}
                            disabled={props.disabled}
                            className={classNames(styles["input"], { [styles["input-error"]]: meta.touched && meta.error })}
                        />
                        <div className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
                    </div>
                );
            }}
        </Field>
    );
};
