import { RequestApi } from "@omnijus/common";
import { EscritorioRequestApi } from "lib/http/escritorio-request-api";
import { PagedResult } from "lib/paged-result";
import { PaginationInfo } from "lib/pagination-info";
import { UsuarioCommand } from "./usuario-command";
import { UsuarioFilter } from "./usuario-filter";
import { UsuarioViewModel } from "./usuario-viewmodel";

const usuarioUrl = process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api";

export const UsuarioService = {
    Listar: async (filtro?: UsuarioFilter, paginationInfo?: PaginationInfo) => {
        const url = new URL(`${usuarioUrl}/v1/Usuario`);
        url.search = RequestApi.objectToQueryString({
            ...filtro,
            ...paginationInfo,
        });
        return await EscritorioRequestApi.get<PagedResult<UsuarioViewModel>>(url.toString());
    },
    Obter: async (id: string) => {
        const url = `${usuarioUrl}/v1/Usuario/${id}`;
        return await EscritorioRequestApi.get<UsuarioViewModel>(url);
    },
    ObterPorCpf: async (cpf: string) => {
        const url = `${usuarioUrl}/v1/Usuario/cpf/${cpf}`;
        return await EscritorioRequestApi.get<UsuarioViewModel>(url);
    },
    Salvar: async (usuario: UsuarioCommand) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario`);

        return await EscritorioRequestApi.post<UsuarioViewModel>(url.toString(), usuario);
    },
    Atualizar: async (usuario: UsuarioCommand) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario`);
        return await EscritorioRequestApi.put<UsuarioViewModel>(url.toString(), usuario);
    },
    Remover: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}`);

        return await EscritorioRequestApi.delete(url.toString());
    },
    Ativar: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}/Unlock`);

        await EscritorioRequestApi.put(url.toString());
    },
    Inativar: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}/Lock`);

        await EscritorioRequestApi.put(url.toString());
    },
};
