import ExcelJS from "exceljs";

export const ChangeCellColor = (rowNumber: number, cellNumbers: number[], color: string, sheet: ExcelJS.Worksheet) => {
    cellNumbers.forEach((cellNumber) => {
        sheet.getRow(rowNumber).getCell(cellNumber).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
                argb: color,
            },
            bgColor: {
                argb: "FF000000",
            },
        };
    });
};

export const ChangeRowColor = (rowNumber: number, color: string, sheet: ExcelJS.Worksheet) => {
    sheet.getRow(rowNumber).eachCell({ includeEmpty: false }, (cell) => {
        sheet.getCell(cell.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
                argb: color,
            },
            bgColor: {
                argb: "FF000000",
            },
        };
    });
};

export const SetAlignment = (
    rowNumber: number,
    cellNumbers: number[],
    alignment: "left" | "center" | "right",
    sheet: ExcelJS.Worksheet
) => {
    cellNumbers.forEach((cellNumber) => {
        sheet.getRow(rowNumber).getCell(cellNumber).alignment = { horizontal: alignment };
    });
};

export const SetAlignmentByRange = (
    rowNumber: number,
    startCell: number,
    endCell: number,
    alignment: "left" | "center" | "right",
    sheet: ExcelJS.Worksheet
) => {
    while (startCell < endCell) {
        sheet.getRow(rowNumber).getCell(startCell).alignment = { horizontal: alignment };
        startCell++;
    }
};

export const SetCellValue = (rowNumber: number, cellNumber: number, value: string, sheet: ExcelJS.Worksheet) => {
    sheet.getRow(rowNumber).getCell(cellNumber).value = value;
};

export const MergeCells = (rowNumber: number, startCell: number, endCell: number, sheet: ExcelJS.Worksheet) => {
    sheet.mergeCells(
        `${sheet.getRow(rowNumber).getCell(startCell).address}:${sheet.getRow(rowNumber).getCell(endCell).address}`
    );
};

export const SetFontSize = (size: number, sheet: ExcelJS.Worksheet) => {
    sheet.eachRow((row) => {
        row.eachCell((cell, colNumber) => {
            row.getCell(colNumber).font = { size: size };
        });
    });
};

export const SetFontBold = (rowNumber: number, sheet: ExcelJS.Worksheet) => {
    sheet.getRow(rowNumber).font = { bold: true };
};

export const InsertEmptyRow = (position: number, sheet: ExcelJS.Worksheet) => {
    sheet.insertRow(position, {});
};

export const SetBorder = (border: "thin" | "medium" | "thick", sheet: ExcelJS.Worksheet) => {
    sheet.columns.forEach((column) => {
        column.border = {
            top: { style: border },
            left: { style: border },
            bottom: { style: border },
            right: { style: border },
        };
    });
};

export const AutoSizeColumns = (sheet: ExcelJS.Worksheet) => {
    sheet.columns.forEach((column) => {
        var maxLength = 0;
        column["eachCell"]!({ includeEmpty: true }, (cell) => {
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
    });
};
