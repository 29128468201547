import { useField } from "formik";
import React from "react";
import { useFiltroAplicado } from "../filtros-aplicados/filtros-aplicados-context";
import "./inputs.scss";
import { OmnijusCheckboxMarcarTodosField } from "./omnijus-checkbox-marcar-todos-field";

interface OmnijusCheckboxFieldProps {
    label?: string;
    name: string;
    options: Array<{ label: string; value: any; group?: string; onCheck?: (value: boolean) => void }>;
    inline?: boolean;
    optionsClassName?: string;
    optionClassName?: string;
    nameMarcarTodos?: string;
    onMarcarTodos?: (v: boolean) => void;
    groupOptions?: boolean;
    disabled?: boolean;
}

export const OmnijusCheckboxField = (props: OmnijusCheckboxFieldProps) => {
    const { onChangeFiltroAplicado } = useFiltroAplicado();
    const [field, meta, helpers] = useField<Array<any>>(props.name);

    return (
        <div className="form-field-control">
            <div className="header">
                {props.label ? (
                    <label className="label" htmlFor={props.name}>
                        {props.label}
                    </label>
                ) : null}
                {!props.disabled && props.nameMarcarTodos ? (
                    <OmnijusCheckboxMarcarTodosField
                        name={props.nameMarcarTodos}
                        onMarcarTodos={(checked) => {
                            helpers.setValue(checked ? props.options.map((o) => o.value) : []);

                            if (props.onMarcarTodos) {
                                props.onMarcarTodos(checked);
                            }
                        }}
                    />
                ) : null}
            </div>
            <div className={`omni-check ${props.optionsClassName}`}>
                {props.groupOptions
                    ? Object.entries(
                          props.options
                              .filter((o) => o.group !== undefined)
                              .reduce((prev, curr) => {
                                  prev[curr.group!] = prev[curr.group!] || [];
                                  prev[curr.group!].push(curr);

                                  return prev;
                              }, {} as { [id: string]: Array<{ label: string; value: any; group?: string; onCheck?: (value: boolean) => void }> })
                      ).map((e) => (
                          <div key={e[0]} className="option-group">
                              <h4 className="name">{e[0]}</h4>
                              <div className="options">
                                  {e[1].map((o) => (
                                      <div className={`option ${props.optionClassName}`} key={o.value}>
                                          <input
                                              id={`${props.name} ${o.value}`}
                                              type="checkbox"
                                              name={props.name}
                                              value={o.value}
                                              checked={field.value ? field.value.includes(o.value) : false}
                                              disabled={props.disabled}
                                              onChange={(e) => {
                                                  let valuesCopy = field.value ? [...field.value] : [];

                                                  if (e.target.checked) {
                                                      valuesCopy.push(o.value);
                                                  } else {
                                                      valuesCopy.splice(valuesCopy.indexOf(o.value), 1);
                                                  }

                                                  helpers.setValue(valuesCopy);

                                                  onChangeFiltroAplicado({
                                                      label: props.label || props.name,
                                                      value: e.target.value,
                                                  });

                                                  if (o.onCheck) {
                                                      o.onCheck(e.target.checked);
                                                  }
                                              }}
                                              className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                                          />
                                          <label htmlFor={`${props.name} ${o.value}`} className="label">
                                              {o.label}
                                          </label>
                                      </div>
                                  ))}
                              </div>
                          </div>
                      ))
                    : props.options.map((o, i) => (
                          <div className={`option ${props.optionClassName}`} key={o.value}>
                              <input
                                  id={`${props.name} ${o.value}`}
                                  type="checkbox"
                                  name={props.name}
                                  value={o.value}
                                  checked={field.value ? field.value.includes(o.value) : false}
                                  disabled={props.disabled}
                                  onChange={(e) => {
                                      let valuesCopy = field.value ? [...field.value] : [];

                                      if (e.target.checked) {
                                          valuesCopy.push(o.value);
                                      } else {
                                          valuesCopy.splice(valuesCopy.indexOf(o.value), 1);
                                      }

                                      helpers.setValue(valuesCopy);

                                      onChangeFiltroAplicado({
                                          label: props.label || props.name,
                                          value: e.target.value,
                                      });

                                      if (o.onCheck) {
                                          o.onCheck(e.target.checked);
                                      }
                                  }}
                                  className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                              />
                              <label htmlFor={`${props.name} ${o.value}`} className="label">
                                  {o.label}
                              </label>
                          </div>
                      ))}
            </div>
            <div className="error">{meta.touched && meta.error ? meta.error : ""}</div>
        </div>
    );
};
