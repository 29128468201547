import { OmnijusLogo, TextButton, useAuth } from "@omnijus/common";
import { EscritorioViewModel } from "lib/http/escritorio/models/escritorio-viewmodel";
import React from "react";
import styles from "./lista-escritorios.module.scss";

export const ListaEscritorios = ({
  escritorios,
  setIdEscritorio,
}: {
  escritorios: EscritorioViewModel[];
  setIdEscritorio: (idEscritorio: string) => void;
}) => {
  const auth = useAuth();
  const item = (c: EscritorioViewModel) => (
    <li key={c.id} onClick={() => setIdEscritorio(c.id)}>
      {c.nome}
    </li>
  );

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <OmnijusLogo />
        </div>
        <div className={styles.cardBody}>
          <h1>Olá, {auth.user?.profile.name ?? auth.user?.profile.nickname}</h1>
          <p>Selecione um escritório</p>
        </div>
        <ul>{escritorios.map((c) => item(c))}</ul>
        <p className={styles.trocaUsuario}>
          <TextButton onClick={() => auth.userManager.signoutRedirect()}>
            Sair e tentar com outro usuário
          </TextButton>
        </p>
      </div>
    </div>
  );
};
