import React from "react";
import styles from "./rotulo-movimentacao.module.scss";

export interface RotuloProps {
    nome: string;
    descricao?: string;
    corFundo: string;
    compacto?: boolean;
}

export const Rotulo = (props: RotuloProps) => {
    return (
        <span
            className={`${styles.rotulo} ${props.compacto ? styles.compacto : ""}`}
            style={{ backgroundColor: `#${props.corFundo}` }}
            title={props.nome}
        >
            <div>{props.nome}</div>
        </span>
    );
};
