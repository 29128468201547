import { RequestApi as RequestApiCommon } from "@omnijus/common";
import { CLIENT_ID } from "./client-id";

function getIdUsuario() {
  const tokenKey = `oidc.user:${process.env.REACT_APP_AUTH_URL}:${CLIENT_ID}`;
  const tokenStr = sessionStorage.getItem(tokenKey);
  return tokenStr ? JSON.parse(tokenStr).profile?.sub : "";
}

function getTokenKey() {
  return `omnijus.${getIdUsuario()}.id-escritorio`;
}

export function getSessionIdEscritorio() {
  const idEscritorio = sessionStorage.getItem(getTokenKey());
  return idEscritorio;
}

export function setSessionIdEscritorio(idEscritorio: string) {
  sessionStorage.setItem(getTokenKey(), idEscritorio);
}

export function clearSessionIdEscritorio() {
  sessionStorage.removeItem(getTokenKey());
}

const requestApi = new RequestApiCommon(CLIENT_ID, {
  beforeSendHeaders: (headers) => {
    const idEscritorio = getSessionIdEscritorio();
    if (idEscritorio) {
      headers.append("ESCRITORIO-ID", idEscritorio);
    }
  },
});

export const EscritorioRequestApi = requestApi;
