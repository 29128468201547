import React from "react";
import { ButtonOutlined } from "shared/buttons/button-outlined/button-outlined";
import { OmnijusFiltroPerfil } from "shared/form/custom-fields/omnijus-filtro-perfil";
import styles from "../usuarios.module.scss";

export interface ItemFormPerfilProps {
    hasBtnAdd: boolean;
}

export const ItemFormPerfil = (props: ItemFormPerfilProps) => {
    return (
        <div className={styles.gridForm}>
            <OmnijusFiltroPerfil />
            {props.hasBtnAdd && (
                <div className={styles.itemAdd}>
                    <ButtonOutlined type="submit">Adicionar</ButtonOutlined>
                </div>
            )}
        </div>
    );
};
