import {
    ActionFeedback,
    Back,
    ButtonPrimary,
    ButtonSecondary,
    Loading,
    OmnijusCard,
    SelectObject,
} from "@omnijus/common";
import { IdStatusPeticionamento } from "lib/http/peticionamento/models/id-status-peticionamento";
import { SolicitacaoPeticionamentoListada } from "lib/http/peticionamento/models/solicitacao-peticionamento-listada";
import { SolicitacaoPeticionamentoMotivoReprovacao } from "lib/http/peticionamento/models/solicitacao-peticionamento-motivo-reprovacao";
import {
    encaminharAnaliseEscritorio,
    listarSolicitacaoPeticionamentoMotivoReprovacao,
    obterSolicitacaoPeticionamento,
    responderSolicitacaoPeticionamento,
} from "lib/http/peticionamento/peticionamento-service";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WrapperAcessoAcaoEscritorio } from "shared/navigation/acessos-helper";
import { CardInfoSolicitacaoPeticionamento } from "./card-info-solicitacao-peticionamento";
import { CardMovimentoGerador } from "./card-movimento-gerador";
import { DocumentosProcesso } from "./documentos-processo";
import { ListaDocumentosPeticionamento } from "./lista-documentos-peticionamento";
import styles from "./listar-validacoes.module.scss";
import { ModalAprovarComRessalva } from "./modal-aprovar-com-ressalva";
import { ModalMotivosReprovacao } from "./modal-motivos-reprovacao";
import { ModalReprovar } from "./modal-reprovar";
import { PdfViewer } from "./pdf-viewer";

export const DocumentosPeticionamento = () => {
    const [motivosReprovacao, setMotivosReprovacao] = useState<SolicitacaoPeticionamentoMotivoReprovacao[]>();
    const [isModalAprovarComRessalvaOpen, setModalAprovarComRessalvaOpen] = useState(false);
    const [isModalReprovarOpen, setModalReprovarOpen] = useState(false);
    const [isModalMotivosReprovacaoOpen, setModalMotivosReprovacaoOpen] = useState(false);
    const [promise, setPromise] = useState<Promise<SolicitacaoPeticionamentoListada | undefined>>();
    const [documentoSelecionado, setDocumentoSelecionado] = useState<SelectObject | undefined>();
    const { id } = useParams<{ id: string }>();

    const handleDocumentoSelecionado = useCallback((documentoSelecionado: SelectObject | undefined) => {
        setDocumentoSelecionado(documentoSelecionado);
    }, []);

    useEffect(() => {
        (async () => {
            if (id) {
                setMotivosReprovacao(await listarSolicitacaoPeticionamentoMotivoReprovacao(id));

                setPromise(obterSolicitacaoPeticionamento(id));
            }
        })();
    }, [id]);

    return (
        <Loading promise={promise}>
            {(solicitacaoPeticionamento) =>
                solicitacaoPeticionamento && (
                    <>
                        <Back />

                        <h1 className={styles.titulo}>
                            Validação de: {solicitacaoPeticionamento.descricaoProvidencia}
                        </h1>

                        <CardInfoSolicitacaoPeticionamento
                            idSolicitacaoPeticionamento={solicitacaoPeticionamento.idSolicitacaoPeticionamento}
                            statusSolicitacaoPeticionamento={solicitacaoPeticionamento.statusSolicitacaoPeticionamento}
                            nomeCliente={solicitacaoPeticionamento.nomeCliente}
                            numeroProcesso={solicitacaoPeticionamento.numeroProcesso}
                            modal="2Colunas"
                            descricaoProvidencia={solicitacaoPeticionamento.descricaoProvidencia}
                        />

                        <div className={`${styles.botoesSuperiores}`}>
                            {motivosReprovacao && motivosReprovacao.length > 0 && (
                                <ButtonSecondary
                                    onClick={() => {
                                        setModalMotivosReprovacaoOpen(true);
                                    }}
                                >
                                    Ver motivos de reprovação
                                </ButtonSecondary>
                            )}

                            {solicitacaoPeticionamento.idStatusSolicitacaoPeticionamento ===
                                IdStatusPeticionamento.ReprovadoEscritorio && (
                                <WrapperAcessoAcaoEscritorio tags={["validacoes.download"]}>
                                    <ButtonSecondary
                                        onClick={async () => {
                                            await ActionFeedback.processing({
                                                title: "Processando...",
                                                execution: encaminharAnaliseEscritorio(
                                                    solicitacaoPeticionamento.idSolicitacaoPeticionamento
                                                ),
                                                onError: async (error: any) =>
                                                    await ActionFeedback.error({
                                                        icon: "error",
                                                        html: error?.json?.erros
                                                            ? error.json.erros.join("<br />")
                                                            : error?.json?.title
                                                            ? error?.json?.title
                                                            : "Não foi possível concluir esta operação. Tente novamente mais tarde.",
                                                    }),
                                            });

                                            await ActionFeedback.info({
                                                text: "Encaminhado para análise do escritório!",
                                            });

                                            window.location.reload();
                                        }}
                                    >
                                        Encaminhar Análise Escritório
                                    </ButtonSecondary>
                                </WrapperAcessoAcaoEscritorio>
                            )}
                        </div>

                        <div className={styles.marginTop}>
                            {solicitacaoPeticionamento.idSolicitacaoPeticionamento && (
                                <>
                                    <div className={styles.formIncluirDocumentos}>
                                        <div style={{ overflow: "auto" }}>
                                            <CardMovimentoGerador
                                                handleDocumentoSelecionado={handleDocumentoSelecionado}
                                                idProcesso={solicitacaoPeticionamento.idProcesso}
                                                idProcessoMovimento={solicitacaoPeticionamento.idProcessoMovimento}
                                            />

                                            <ListaDocumentosPeticionamento
                                                handleDocumentoSelecionado={handleDocumentoSelecionado}
                                                idProcesso={solicitacaoPeticionamento.idProcesso}
                                                idSolicitacaoPeticionamento={
                                                    solicitacaoPeticionamento.idSolicitacaoPeticionamento
                                                }
                                                idStatusSolicitacaoPeticionamento={
                                                    solicitacaoPeticionamento.idStatusSolicitacaoPeticionamento
                                                }
                                                documentoSelecionado={documentoSelecionado}
                                            />

                                            <DocumentosProcesso
                                                handleDocumentoSelecionado={handleDocumentoSelecionado}
                                                idProcesso={solicitacaoPeticionamento.idProcesso}
                                            />
                                        </div>

                                        {documentoSelecionado && (
                                            <div>
                                                <PdfViewer documentoSelecionado={documentoSelecionado} />
                                            </div>
                                        )}
                                    </div>

                                    {solicitacaoPeticionamento.idStatusSolicitacaoPeticionamento ===
                                        IdStatusPeticionamento.PendenteAprovacao && (
                                        <div className={styles.marginTop}>
                                            <OmnijusCard
                                                body={
                                                    <div className={styles.botoes}>
                                                        {solicitacaoPeticionamento.possuiDocumentos && (
                                                            <ButtonPrimary
                                                                onClick={async () => {
                                                                    await ActionFeedback.processing({
                                                                        title: "Aprovando solicitação de peticionamento",
                                                                        execution: responderSolicitacaoPeticionamento(
                                                                            solicitacaoPeticionamento.idSolicitacaoPeticionamento,
                                                                            true
                                                                        ),
                                                                        onError: async (error: any) =>
                                                                            await ActionFeedback.error({
                                                                                icon: "error",
                                                                                html: error?.json?.erros
                                                                                    ? error.json.erros.join("<br />")
                                                                                    : error?.json?.title
                                                                                    ? error?.json?.title
                                                                                    : "",
                                                                            }),
                                                                    });

                                                                    await ActionFeedback.info({
                                                                        title: "Solicitação de peticionamento aprovada",
                                                                    });

                                                                    window.location.reload();
                                                                }}
                                                                className={`${styles.botao} ${styles.botaoSalvar}`}
                                                            >
                                                                Aprovar
                                                            </ButtonPrimary>
                                                        )}

                                                        {solicitacaoPeticionamento.possuiDocumentos && (
                                                            <ButtonPrimary
                                                                onClick={() => setModalAprovarComRessalvaOpen(true)}
                                                                className={`${styles.botao} ${styles.botaoAprovarComRessalva}`}
                                                            >
                                                                Aprovar com ressalva
                                                            </ButtonPrimary>
                                                        )}

                                                        <ButtonPrimary
                                                            onClick={() => setModalReprovarOpen(true)}
                                                            className={`${styles.botao} ${styles.botaoSair}`}
                                                        >
                                                            Reprovar
                                                        </ButtonPrimary>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {solicitacaoPeticionamento.idSolicitacaoPeticionamento && (
                            <ModalAprovarComRessalva
                                idSolicitacaoPeticionamento={solicitacaoPeticionamento.idSolicitacaoPeticionamento}
                                nomeCliente={solicitacaoPeticionamento.nomeCliente}
                                numeroProcesso={solicitacaoPeticionamento.numeroProcesso}
                                statusSolicitacaoPeticionamento={
                                    solicitacaoPeticionamento.statusSolicitacaoPeticionamento
                                }
                                descricaoProvidencia={solicitacaoPeticionamento.descricaoProvidencia}
                                isModalOpen={isModalAprovarComRessalvaOpen}
                                onCloseModal={() => setModalAprovarComRessalvaOpen(false)}
                            />
                        )}

                        {solicitacaoPeticionamento.idSolicitacaoPeticionamento &&
                            solicitacaoPeticionamento.idProvidencia && (
                                <ModalReprovar
                                    idSolicitacaoPeticionamento={solicitacaoPeticionamento.idSolicitacaoPeticionamento}
                                    nomeCliente={solicitacaoPeticionamento.nomeCliente}
                                    numeroProcesso={solicitacaoPeticionamento.numeroProcesso}
                                    statusSolicitacaoPeticionamento={
                                        solicitacaoPeticionamento.statusSolicitacaoPeticionamento
                                    }
                                    idProvidencia={solicitacaoPeticionamento.idProvidencia}
                                    descricaoProvidencia={solicitacaoPeticionamento.descricaoProvidencia}
                                    isModalOpen={isModalReprovarOpen}
                                    onCloseModal={() => setModalReprovarOpen(false)}
                                />
                            )}

                        {motivosReprovacao && (
                            <ModalMotivosReprovacao
                                isModalOpen={isModalMotivosReprovacaoOpen}
                                onCloseModal={() => setModalMotivosReprovacaoOpen(false)}
                                motivosReprovacao={motivosReprovacao}
                            />
                        )}
                    </>
                )
            }
        </Loading>
    );
};
