import { EscritorioRequestApi } from "../escritorio-request-api";
import { FiltroCliente } from "./models/filtro-cliente";
import { FiltroContrato } from "./models/filtro-contrato";
import { FiltroEmpresa } from "./models/filtro-empresa";

const filtrosClienteUrl = process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api";

export const FiltrosClienteService = {
  listarClientes: () => {
    const url = `${filtrosClienteUrl}/v1/Cliente`;
    return EscritorioRequestApi.get<FiltroCliente[]>(url);
  },
  listarContratos: (idCliente?: string) => {
    const url = new URL(
      `${filtrosClienteUrl}/Contrato/ObterContratoPorCliente`
    );
    idCliente && url.searchParams.set("idCliente", idCliente);
    return EscritorioRequestApi.get<FiltroContrato[]>(url.toString());
  },
  listarEmpresas: (idContrato?: string) => {
    const url = new URL(
      `${filtrosClienteUrl}/EmpresaCliente/ListarEmpresasContrato`
    );
    idContrato && url.searchParams.set("idContrato", idContrato);
    return EscritorioRequestApi.get<FiltroEmpresa[]>(url.toString());
  },
};
