import Cleave from "cleave.js/react";
import { Field, FieldProps } from "formik";
import styles from "../field.module.scss";
import specificStyles from "./omnijus-campo-numero-processo.module.scss";
import React from "react";

export const OmnijusCampoNumeroProcesso = (props: {
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  actionIcon?: JSX.Element;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onAction?: (value: string) => void;
}) => {
  return (
    <div className={`${styles["field-control"]} ${props.className || ""}`}>
      {props.label ? (
        <label className={styles["label"]} htmlFor={props.name}>
          {props.label}
        </label>
      ) : null}
      <Field name={props.name} id={props.name}>
        {({ field, meta }: FieldProps) => (
          <>
            <div className={specificStyles["input-wrapper"]}>
              <Cleave
                disabled={props.disabled}
                options={{
                  blocks: [7, 2, 4, 1, 2, 4],
                  delimiters: ["-", ".", ".", ".", "."],
                  numericOnly: true,
                }}
                className={styles["input"]}
                id={props.name}
                {...field}
                placeholder={props.placeholder}
                onChange={(e: any) => {
                  props.onChange && props.onChange(e);
                  field.onChange(e);
                }}
              />
              {props.onAction ? (
                <button
                  className={specificStyles["acao"]}
                  type="button"
                  onClick={() => props.onAction!(field.value)}
                >
                  {props.actionIcon}
                </button>
              ) : null}
            </div>
            <p className={styles["error"]}>
              {meta.touched && meta.error ? meta.error : ""}
            </p>
          </>
        )}
      </Field>
    </div>
  );
};
