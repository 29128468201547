import { useField } from "formik";
import React from "react";
import "./inputs.scss";

interface OmnijusCheckboxFieldProps {
    name: string;
    onMarcarTodos?: (v: boolean) => void;
}

export const OmnijusCheckboxMarcarTodosField = (props: OmnijusCheckboxFieldProps) => {
    const [field, , helpers] = useField<boolean>(props.name);

    return (
        <div className="marcar-todos">
            <input
                id={`${props.name}-opcao-marcar-todos`}
                name={`${props.name}-opcao-marcar-todos`}
                type="checkbox"
                checked={field.value}
                onChange={(e) => {
                    helpers.setValue(e.target.checked);

                    if (props.onMarcarTodos) {
                        props.onMarcarTodos(e.target.checked);
                    }
                }}
            />
            <label htmlFor={`${props.name}-opcao-marcar-todos`} className="label">
                Marcar todas opções
            </label>
        </div>
    );
};
