export const Validation = {
    validateCPF: (cpfOriginal: string) => {
        let cpfNumbers = cpfOriginal.replace(/[^0-9]+/gi, "");
        let primeiroDigitoCalculado = 0;
        let segundoDigitoCalculado = 0;
        let i = 1;
        let result = false;

        if (cpfNumbers.length === 11) {
            let primeiroDigitoOriginal = Number(cpfNumbers.charAt(9));
            let segundoDigitoOriginal = Number(cpfNumbers.charAt(10));
            cpfNumbers = cpfNumbers.substring(0, 9);

            for (let digito of cpfNumbers) {
                primeiroDigitoCalculado += Number(digito) * i++;
            }
            primeiroDigitoCalculado = primeiroDigitoCalculado % 11;
            primeiroDigitoCalculado = primeiroDigitoCalculado < 10 ? primeiroDigitoCalculado : 0;

            i = 0;
            for (let digito of cpfNumbers) {
                segundoDigitoCalculado += Number(digito) * i++;
            }
            segundoDigitoCalculado += primeiroDigitoCalculado * i;
            segundoDigitoCalculado = segundoDigitoCalculado % 11;
            segundoDigitoCalculado = segundoDigitoCalculado < 10 ? segundoDigitoCalculado : 0;

            if (
                primeiroDigitoCalculado === primeiroDigitoOriginal &&
                segundoDigitoCalculado === segundoDigitoOriginal
            ) {
                result = true;
            }
        }

        return result;
    },
    validateCNPJ: (cnpjOriginal: string) => {
        let cnpjNumbers = cnpjOriginal.replace(/[^0-9]+/gi, "");
        let primeiroDigitoPesos = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        let primeiroDigitoCalculado = 0;
        let segundoDigitoPesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        let segundoDigitoCalculado = 0;
        let result = false;

        if (cnpjNumbers.length === 14) {
            let primeiroDigitoOriginal = Number(cnpjNumbers.charAt(12));
            let segundoDigitoOriginal = Number(cnpjNumbers.charAt(13));
            cnpjNumbers = cnpjNumbers.substring(0, 12);

            for (let i = 0; i < 12; i++) {
                primeiroDigitoCalculado += Number(cnpjNumbers[i]) * primeiroDigitoPesos[i];
            }
            primeiroDigitoCalculado = primeiroDigitoCalculado % 11;
            primeiroDigitoCalculado = primeiroDigitoCalculado > 1 ? 11 - primeiroDigitoCalculado : 0;

            for (let i = 0; i < 12; i++) {
                segundoDigitoCalculado += Number(cnpjNumbers[i]) * segundoDigitoPesos[i];
            }
            segundoDigitoCalculado += primeiroDigitoCalculado * segundoDigitoPesos[12];
            segundoDigitoCalculado = segundoDigitoCalculado % 11;
            segundoDigitoCalculado = segundoDigitoCalculado > 1 ? 11 - segundoDigitoCalculado : 0;

            if (
                primeiroDigitoCalculado === primeiroDigitoOriginal &&
                segundoDigitoCalculado === segundoDigitoOriginal
            ) {
                result = true;
            }
        }

        return result;
    },
    validateOAB: (oabOriginal: string) => {
        let oabAlfaNumeric = oabOriginal.toUpperCase().replace(/[^0-9A-Z]+/gi, "");
        let result = true;

        if (oabAlfaNumeric.length === 8) {
            if (
                !(
                    oabAlfaNumeric[0] >= "A" &&
                    oabAlfaNumeric[0] <= "Z" &&
                    oabAlfaNumeric[1] >= "A" &&
                    oabAlfaNumeric[1] <= "Z"
                )
            ) {
                result = false;
            } else {
                for (let char of oabAlfaNumeric.substring(2)) {
                    if (!(char >= "0" && char <= "9")) {
                        result = false;
                    }
                }
            }
        } else {
            result = false;
        }

        return result;
    },
};
