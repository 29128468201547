import { mdiContentCopy, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import {
    ActionFeedback,
    CardFiltros,
    Loading,
    OmnijusCard,
    OmnijusSelectField,
    OmnijusTextField,
    RequestApi,
    TextButton,
} from "@omnijus/common";
import { parsePerfilFilter, PerfilFilter } from "lib/http/configuracoes/perfil/perfil-filter";
import { PerfilService } from "lib/http/configuracoes/perfil/perfil-service";
import { PerfilViewModel } from "lib/http/configuracoes/perfil/perfil-viewmodel";
import { PagedResult } from "lib/paged-result";
import { PaginationInfo } from "lib/pagination-info";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonOutlined } from "shared/buttons/button-outlined/button-outlined";
import styles from "./perfil.module.scss";

export const Perfis = () => {
    const history = useHistory();
    const query = useLocation().search;
    const [dataBusca, setDataBusca] = useState(new Date());

    const [promise, setPromise] = useState<Promise<PagedResult<PerfilViewModel> | undefined>>();
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        sortColumn: "",
        sortDirection: "asc",
        pageNumber: 1,
        rowsPerPage: 20,
    });

    const filtro = useMemo(() => parsePerfilFilter(query), [query]);

    useEffect(() => {
        setPromise(PerfilService.listar(filtro, paginationInfo));
    }, [filtro, paginationInfo, dataBusca]);

    const buscar = useCallback(
        (filtros: PerfilFilter) => {
            const search = RequestApi.objectToQueryString(filtros);
            history.replace({ search });
            setDataBusca(new Date());
        },
        [history]
    );

    const remover = useCallback(
        async (id: string) => {
            const resposta = await ActionFeedback.confirm("Deseja remover o perfil?");

            if (!resposta) {
                return;
            }

            await ActionFeedback.processing({
                title: "Removendo o perfil...",
                execution: PerfilService.excluir(id),
            });

            setDataBusca(new Date());
        },
        [setDataBusca]
    );

    const clonar = useCallback(
        async (id: string) => {
            const resposta = await ActionFeedback.confirm("Deseja copiar o perfil?");

            if (!resposta) {
                return;
            }

            await ActionFeedback.processing({
                title: "Copiando o perfil...",
                execution: PerfilService.copiar(id),
            });

            setDataBusca(new Date());
        },
        [setDataBusca]
    );

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            rowsPerPage,
            pageNumber: 1,
        }));
    };

    const handleSort = (column: IDataTableColumn<any>, sortDirection: "asc" | "desc") => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            sortDirection,
            sortColumn: String(column.id),
            pageNumber: 1,
        }));
    };

    const handleChangePage = (page: number) => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            pageNumber: page,
        }));
    };

    const columns = useMemo<IDataTableColumn<PerfilViewModel>[]>(
        () => [
            {
                name: "Descrição",
                selector: "descricao",
                id: "descricao",
                maxWidth: "100%",
                sortable: true,
                wrap: false,
                cell: ({ descricao, idEscritorio }) => descricao + (idEscritorio ? "" : " (Global)"),
            },
            {
                name: "Tipo",
                maxWidth: "140px",
                sortable: true,
                cell: (row) => (row.idEscritorio ? "Personalizado" : "Padrão"),
            },
            {
                name: "Status",
                maxWidth: "20px",
                selector: "ativo",
                sortable: true,
                id: "ativo",
                cell: (row) => (row.ativo ? "Ativo" : "Inativo"),
            },
            {
                name: "",
                maxWidth: "10px",
                sortable: false,
                id: "clonar",
                cell: ({ id }) => (
                    <TextButton onClick={() => clonar(id)}>
                        <Icon path={mdiContentCopy} size={1} />
                    </TextButton>
                ),
            },
            {
                name: "",
                maxWidth: "10px",
                sortable: false,
                id: "excluir",
                cell: ({ idEscritorio, id }) =>
                    idEscritorio && (
                        <TextButton onClick={() => remover(id)}>
                            <Icon path={mdiTrashCan} size={1} />
                        </TextButton>
                    ),
            },
        ],
        [remover, clonar]
    );

    return (
        <div className="perfis">
            <h2>Perfis</h2>
            <div className={styles.cardFiltro}>
                <CardFiltros
                    textoBotao="Filtrar"
                    onBuscar={async (values) => {
                        buscar(values);
                    }}
                    initialValues={filtro}
                    botaoFiltrarInline
                >
                    <FormFiltroPerfil />
                </CardFiltros>
            </div>
            <Loading promise={promise}>
                {(pagedResult) => (
                    <OmnijusCard
                        body={
                            <>
                                <DataTable
                                    title={"Perfil"}
                                    actions={
                                        <ButtonOutlined onClick={() => history.push("/configuracoes/perfil/novo")}>
                                            Novo Perfil
                                        </ButtonOutlined>
                                    }
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={paginationInfo.rowsPerPage}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={pagedResult?.results || []}
                                    paginationServer
                                    onChangeRowsPerPage={handleRowsPerPage}
                                    paginationDefaultPage={pagedResult?.pageNumber ?? 1}
                                    onChangePage={handleChangePage}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                    onSort={handleSort}
                                    sortServer
                                    defaultSortField={
                                        columns.find((c) => c.id === paginationInfo.sortColumn)?.selector as string
                                    }
                                    defaultSortAsc={paginationInfo.sortDirection === "asc"}
                                    paginationTotalRows={pagedResult?.total}
                                    onRowClicked={(row) => history.push(`/configuracoes/perfil/editar/${row.id}`)}
                                    columns={columns}
                                />
                            </>
                        }
                    />
                )}
            </Loading>
        </div>
    );
};

export const FormFiltroPerfil = () => {
    return (
        <>
            <OmnijusTextField name="descricao" label="Descrição" />
            <OmnijusSelectField
                name="relacionadoEscritorio"
                label="Tipo"
                options={[
                    { label: "Personalizado", value: "true" },
                    { label: "Padrão", value: "false" },
                ]}
            />
        </>
    );
};
