import { NomesTiposParametros, RequestApi } from "@omnijus/common";

export interface UsuarioFilter {
    term?: string;
    idPerfil?: string;
    status?: boolean;
}

const tiposParametros: NomesTiposParametros<Required<UsuarioFilter>> = {
    term: "string",
    idPerfil: "string",
    status: "boolean",
};

export const parseUsuarioFilter = (queryString: string): UsuarioFilter =>
    RequestApi.parseQueryString(queryString, tiposParametros);
