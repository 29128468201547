import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../lib/http/filtros/filtros-processo-service";
import { FiltroComarca } from "../../../lib/http/filtros/models/filtro-comarca";
import { OmnijusSelectField } from "@omnijus/common";

const filtroOrgaoName = "IdOrgao";

interface OmnijusFiltroComarcaProps {
  reloadOnChangeName?: string;
  name?: string;
}

export const OmnijusFiltroComarca = (props: OmnijusFiltroComarcaProps) => {
  const formikContext = useFormikContext<any>();
  const { setFieldValue } = formikContext;
  const isFirstRun = useRef(true);
  const name = props.name || "NomeComarcaOrgaoUnidade";
  const idOrgao =
    formikContext.values[props.reloadOnChangeName || filtroOrgaoName];
  const [promiseOpcoesComarca, setPromiseOpcoesComarca] =
    useState<Promise<FiltroComarca[] | undefined>>();

  useEffect(() => {
    isFirstRun.current || setFieldValue(name, undefined);
    isFirstRun.current = false;
    setPromiseOpcoesComarca(ProcessoFiltroService.listarComarca(idOrgao));
  }, [idOrgao, props.reloadOnChangeName, name, setFieldValue]);

  return (
    <Loading promise={promiseOpcoesComarca}>
      {(opcoesComarca) => (
        <OmnijusSelectField
          name={name}
          label="Comarca"
          options={opcoesComarca
            ?.map((o) => ({
              value: o.id.toString(),
              label: o.value.toString(),
            }))
            .sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )}
        />
      )}
    </Loading>
  );
};
