import ExcelJS from "exceljs";
import {
    AutoSizeColumns,
    ChangeCellColor,
    ChangeRowColor,
    InsertEmptyRow,
    MergeCells,
    SetAlignment,
    SetAlignmentByRange,
    SetBorder,
    SetCellValue,
    SetFontBold,
    SetFontSize
} from 'lib/excel-helper';
import { TableHeader } from "lib/export-format";
import { DetalheProcesso } from 'lib/http/processo/models/detalhe-processo';

const GetMaxAdvogados = (data: DetalheProcesso[], poloAtivo: boolean) => {
    let maxAdvogados: number = 0;
    data.forEach((processo) => {
        processo.partes
            .filter((p) => p.poloAtivo === poloAtivo)
            .forEach((parte) => {
                if (parte.advogado.length > maxAdvogados) {
                    maxAdvogados = parte.advogado.length;
                }
            });
    });

    return maxAdvogados;
};

export const CreateWorkbook = (data: DetalheProcesso[], headers: TableHeader[]) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Planilha");

    const maxAdvogadosPoloAtivo: number = GetMaxAdvogados(data, true);
    const maxAdvogadosPoloPassivo: number = GetMaxAdvogados(data, false);

    headers.forEach((h, index) => {
        const col = sheet.getColumn(index + 1);
        col.header = h.label;
        col.width = h.width || h.label.length;
        col.key = h.selector;
        col.style.alignment = { wrapText: h.wrapText, vertical: "top" };
        if (h.reais) {
            col.numFmt = "[$R$-pt-BR] #,##0.00";
        }
    });

    sheet.addRows(data);

    InsertEmptyRow(1, sheet);

    ChangeRowColor(2, "ddaf42", sheet);
    SetAlignmentByRange(2, 1, 15, "center", sheet);

    const corPoloAtivo = "ddcf42";

    sheet.eachRow((row, rowNumber) => {
        if (rowNumber > 2) {
            let cellNumber = 16;
            let headerCellNumber = 16;
            let contadorPoloAtivo = 0;

            if (data[rowNumber - 3]) {
                data[rowNumber - 3].partes
                    .filter((p) => p.poloAtivo)
                    .forEach((parte) => {
                        headerCellNumber++;
                        contadorPoloAtivo++;
                        cellNumber = headerCellNumber;

                        ChangeCellColor(1, [headerCellNumber], corPoloAtivo, sheet);
                        SetAlignment(1, [headerCellNumber], "center", sheet);
                        SetCellValue(1, headerCellNumber, `Polo Ativo ${contadorPoloAtivo}`, sheet);

                        if (!sheet.getRow(1).getCell(headerCellNumber).isMerged) {
                            MergeCells(1, headerCellNumber, headerCellNumber + 1, sheet);
                        }

                        ChangeCellColor(2, [headerCellNumber, headerCellNumber + 1], corPoloAtivo, sheet);
                        SetAlignment(2, [headerCellNumber, headerCellNumber + 1], "center", sheet);
                        SetCellValue(2, headerCellNumber++, "Nome", sheet);
                        SetCellValue(2, headerCellNumber++, "Documento", sheet);
                        SetCellValue(rowNumber, cellNumber++, parte.nome, sheet);
                        SetCellValue(rowNumber, cellNumber++, parte.documento, sheet);

                        ChangeCellColor(1, [headerCellNumber], corPoloAtivo, sheet);
                        SetAlignment(1, [headerCellNumber], "center", sheet);
                        SetCellValue(1, headerCellNumber, `Advogados do Polo Ativo ${contadorPoloAtivo}`, sheet);

                        if (maxAdvogadosPoloAtivo > 0) {
                            if (!sheet.getRow(1).getCell(headerCellNumber).isMerged) {
                                MergeCells(
                                    1,
                                    headerCellNumber,
                                    headerCellNumber + maxAdvogadosPoloAtivo * 2 - 1,
                                    sheet
                                );
                            }
                        }

                        let index = 0;
                        while (index < maxAdvogadosPoloAtivo) {
                            ChangeCellColor(2, [headerCellNumber, headerCellNumber + 1], corPoloAtivo, sheet);
                            SetAlignment(2, [headerCellNumber, headerCellNumber + 1], "center", sheet);
                            SetCellValue(2, headerCellNumber++, "Advogado", sheet);
                            SetCellValue(2, headerCellNumber++, "OAB", sheet);
                            index++;
                        }

                        parte.advogado.forEach((adv) => {
                            SetCellValue(rowNumber, cellNumber++, adv.nomeAdvogado, sheet);
                            SetCellValue(rowNumber, cellNumber++, adv.documentoAdvogado, sheet);
                        });
                    });
            }
        }
    });

    const actualColumnCount = sheet.actualColumnCount + 4;

    const corPoloPassivo = "55FFFF";

    sheet.eachRow((row, rowNumber) => {
        if (rowNumber > 2) {
            let cellNumber = actualColumnCount;
            let headerCellNumber = actualColumnCount;
            let contadorPoloPassivo = 0;

            if (data[rowNumber - 3]) {
                data[rowNumber - 3].partes
                    .filter((p) => !p.poloAtivo)
                    .forEach((parte) => {
                        headerCellNumber++;
                        contadorPoloPassivo++;
                        cellNumber = headerCellNumber;

                        ChangeCellColor(1, [headerCellNumber], corPoloPassivo, sheet);
                        SetAlignment(1, [headerCellNumber], "center", sheet);
                        SetCellValue(1, headerCellNumber, `Polo Passivo ${contadorPoloPassivo}`, sheet);

                        if (!sheet.getRow(1).getCell(headerCellNumber).isMerged) {
                            MergeCells(1, headerCellNumber, headerCellNumber + 1, sheet);
                        }

                        ChangeCellColor(2, [headerCellNumber, headerCellNumber + 1], corPoloPassivo, sheet);
                        SetAlignment(2, [headerCellNumber, headerCellNumber + 1], "center", sheet);
                        SetCellValue(2, headerCellNumber++, "Nome", sheet);
                        SetCellValue(2, headerCellNumber++, "Documento", sheet);
                        SetCellValue(rowNumber, cellNumber++, parte.nome, sheet);
                        SetCellValue(rowNumber, cellNumber++, parte.documento, sheet);

                        ChangeCellColor(1, [headerCellNumber], corPoloPassivo, sheet);
                        SetAlignment(1, [headerCellNumber], "center", sheet);
                        SetCellValue(1, headerCellNumber, `Advogados do Polo Passivo ${contadorPoloPassivo}`, sheet);

                        if (maxAdvogadosPoloPassivo > 0) {
                            if (!sheet.getRow(1).getCell(headerCellNumber).isMerged) {
                                MergeCells(
                                    1,
                                    headerCellNumber,
                                    headerCellNumber + maxAdvogadosPoloPassivo * 2 - 1,
                                    sheet
                                );
                            }
                        }

                        let index = 0;
                        while (index < maxAdvogadosPoloPassivo) {
                            ChangeCellColor(2, [headerCellNumber, headerCellNumber + 1], corPoloPassivo, sheet);
                            SetAlignment(2, [headerCellNumber, headerCellNumber + 1], "center", sheet);
                            SetCellValue(2, headerCellNumber++, "Advogado", sheet);
                            SetCellValue(2, headerCellNumber++, "OAB", sheet);
                            index++;
                        }

                        parte.advogado.forEach((adv) => {
                            SetCellValue(rowNumber, cellNumber++, adv.nomeAdvogado, sheet);
                            SetCellValue(rowNumber, cellNumber++, adv.documentoAdvogado, sheet);
                        });
                    });
            }
        }
    });

    SetBorder("thin", sheet);

    AutoSizeColumns(sheet);

    SetFontBold(1, sheet);
    SetFontBold(2, sheet);

    SetFontSize(8, sheet);

    return workbook;
};
