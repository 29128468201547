import { format } from "date-fns";
import React from "react";
import { Format } from "lib/format";
import "./row-detalhe-processo.scss";
import { DetalheProcesso, Parte } from 'lib/http/processo/models/detalhe-processo';

/*********** Linha da lista ***********/
interface RowItemProps {
    label: string;
    value?: string;
    className?: string;
}

const RowItem = (props: RowItemProps) => {
    return (
        <li className={"item " + (props.className || "")}>
            <span className="label">{props.label}</span>
            <span className="value">{props.value || ""}</span>
        </li>
    );
};
/**********************/

/*********** Grupo de linhas ***********/
interface RowGroupProps {
    title: string;
    children: JSX.Element[];
    className?: string;
}

const RowGroup = (props: RowGroupProps) => {
    return (
        <li className={"row-group " + (props.className || "")}>
            <span className="title">{props.title}</span>
            <ul className="group">{props.children}</ul>
        </li>
    );
};
/**********************/

/*********** Linha de autor ***********/
interface RowAutorProps {
    autor: Parte;
    title?: string;
}

const RowAutor = (props: RowAutorProps) => {
    return (
        <RowGroup title={props.title || "Autor"}>
            <RowItem label="Qualificação" value={props.autor.qualificacao} />
            <RowItem label="Polo ativo" value={props.autor.poloAtivo ? "Sim" : "Não"} />
            <RowItem label="Documento" value={props.autor.documento} />
            <RowItem
                label={"Advogado" + (props.autor.advogado.length > 0 ? "s" : "")}
                value={props.autor.advogado
                    .map((a) => {
                        return a.nomeAdvogado + (a.documentoAdvogado ? " (" + a.documentoAdvogado + ")" : "");
                    })
                    .join(", ")}
            />
        </RowGroup>
    );
};
/**********************/

/*********** Lista principal ***********/
interface RowDetalheProcessoProps {
    data?: DetalheProcesso;
}

export const RowDetalheProcesso = (props: RowDetalheProcessoProps) => {
    return props.data ? (
        <ul className="row-detalhe-processo">
            <RowItem label="Tribunal" value={props.data.tribunal} />
            <RowItem label="Comarca" value={props.data.comarca} />
            <RowItem label="Foro" value={props.data.foro} />
            <RowItem label="Vara" value={props.data.vara} />
            <RowGroup className="span-column-2" title={"Parte" + (props.data.partes.length > 0 ? "s" : "")}>
                {props.data.partes
                    .sort((a, b) => {
                        if (a.poloAtivo) {
                            return -1;
                        } else if (b.poloAtivo) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    .map((p) => (
                        <RowAutor key={p.nome} title={p.nome} autor={p} />
                    ))}
            </RowGroup>
            <RowItem
                label="Data distribuição"
                value={format(new Date(props.data.dataHoraDistribuicao), "dd/MM/yyyy")}
            />
            <RowItem label="Julgador" value={props.data.juiz} />
            <RowItem className="span-column-2" label="Assunto" value={props.data.assunto.join(", ")} />
            <RowItem label="Valor da ação" value={Format.toBRL(props.data.valorAcao)} />
            <RowItem label="Valor do risco" value={Format.toBRL(props.data.valorRisco)} />
            <RowItem label="Status" value={props.data.descricaoStatusProcesso} />
            <RowItem
                label="Rito / Fase / Momento"
                value={`${props.data.descricaoRito} / ${props.data.descricaoFase} / ${props.data.descricaoMomento}`}
            />
        </ul>
    ) : null;
};
/**********************/
