import { Loading, OmnijusSelectField } from "@omnijus/common";
import { Providencia } from "lib/http/providencia/models/providencia";
import { ProvidenciaService } from "lib/http/providencia/providencia-service";
import { sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

interface OmnijusFiltroProvidenciaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

export const OmnijusFiltroProvidencia = (props: OmnijusFiltroProvidenciaProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<Providencia[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(ProvidenciaService.listarPorPerfil());
    }, []);

    const promiseOptions = useMemo(() => {
        return promiseOpcoes?.then((opcoes) => {
            if (!opcoes) {
                return [];
            }

            return sortBy(
                opcoes.map((o) => ({
                    value: o.id.toString(),
                    label: o.descricao,
                })),
                "label"
            );
        });
    }, [promiseOpcoes]);

    return (
        <Loading promise={promiseOptions}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idProvidencia"}
                    label={props.label}
                    options={opcoes}
                />
            )}
        </Loading>
    );
};
