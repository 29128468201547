import { Loading, OmnijusSelectField, SelectObject } from "@omnijus/common";
import { ProcessoFiltroService } from "lib/http/filtros/filtros-processo-service";
import React, { useEffect, useState } from "react";
// import { ProcessoFiltroService } from "../../../lib/http/filtros/filtros-processo-service";
import { FiltroTipoResultadoSentenca } from "../../../lib/http/filtros/models/filtro-tipo-resultado-sentenca";

interface OmnijusFiltroTipoResultadoSentencaProps {
  hideLabel?: boolean;
  name?: string;
  label?: string;
  isMulti?: boolean;
  promiseOpcoesTipoResultadoSentenca?: Promise<
    FiltroTipoResultadoSentenca[] | undefined
  >;
}
export const OmnijusFiltroTipoResultadoSentenca = (
  props: OmnijusFiltroTipoResultadoSentencaProps
) => {
  const [promise, setPromise] =
    useState<Promise<{ id: number; value: string }[] | undefined>>();

  useEffect(() => {
    setPromise(ProcessoFiltroService.listarTipoResultadoSentenca());
  }, []);

  return (
    <Loading promise={promise}>
      {(opcoes) => (
        <OmnijusSelectField
          name={props.name || "IdTipoResultadoSentenca"}
          label={
            props.hideLabel
              ? undefined
              : props.label || "Tipo Resultado Sentença"
          }
          isMulti={props.isMulti}
          options={opcoes
            ?.map((o) => {
              return {
                value: o.id.toString(),
                label: o.value,
              } as SelectObject;
            })
            .sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )}
        />
      )}
    </Loading>
  );
};
