import { Field, FieldProps } from "formik";
import React from "react";
import "./inputs.scss";
import CSS from "csstype";

interface OmnijusCheckboxFieldProps {
    label?: string;
    name: string;
    style?: CSS.Properties;
    disabled?: boolean;
}

export const OmnijusCheckboxFieldSingle = (props: OmnijusCheckboxFieldProps) => {
    return (
        <Field name={props.name} id={props.name}>
            {({ field, meta }: FieldProps) => (
                <div>
                    {props.label ? (
                        <label className="label" htmlFor={props.name}>
                            {props.label}
                        </label>
                    ) : null}
                    <div style={{ marginTop: "1rem" }}>
                        <input
                            {...field}
                            type="checkbox"
                            value={field.value || false}
                            checked={field.value || false}
                            disabled={props.disabled}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                            className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                        />
                    </div>
                    <div className="error">{meta.touched && meta.error ? meta.error : ""}</div>
                </div>
            )}
        </Field>
    );
};
