import {
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiCheckboxOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiCircleSmall,
    mdiCollapseAllOutline,
    mdiExpandAllOutline,
    mdiMinusBoxOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
    ActionFeedback,
    Back,
    ButtonPrimary,
    Loading,
    OmnijusCard,
    OmnijusTextField,
    TypeUtils,
} from "@omnijus/common";
import { Form, Formik, useFormikContext } from "formik";
import { obterModulo } from "lib/http/configuracoes/modulo/modulo-service";
import { ModuloViewModel } from "lib/http/configuracoes/modulo/modulo-viewmodel";
import { PerfilService } from "lib/http/configuracoes/perfil/perfil-service";
import { InserirAtualizarPerfilCommand } from "lib/http/configuracoes/perfil/perfil-viewmodel";
import { getSessionIdEscritorio } from "lib/http/escritorio-request-api";
import React, { useEffect, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useHistory, useParams } from "react-router-dom";
import { OmnijusCheckboxFieldSingle } from "shared/form/fields/omnijus-checkbox-field-single";
import { SpacerV } from "shared/layout/spacer";
import { object as YupObject, string as YupString } from "yup";
import { PerfilProvidencias } from "./perfil-providencias";
import styles from "./perfil.module.scss";
import "./treeview.scss";
import { UsuarioPerfil } from "./usuario-perfil";

const validationSchema = YupObject().shape({
    descricao: YupString()
        .required("Informe o nome do perfil")
        .min(3, "O nome do perfil deve ter no mínimo 3 caracteres")
        .max(30, "O nome do perfil deve ter no máximo 30 caracteres"),
});

export const Perfil = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [promiseCommand, setPromiseCommand] = useState<Promise<InserirAtualizarPerfilCommand | undefined>>();

    const novo = !id;

    useEffect(() => {
        if (novo) {
            setPromiseCommand(
                Promise.resolve({
                    descricao: "",
                    ativo: true,
                    acoes: [],
                    idEscritorio: getSessionIdEscritorio(),
                    providencias: [],
                } as InserirAtualizarPerfilCommand)
            );
        } else {
            setPromiseCommand(
                PerfilService.obter(id).then((perfil) => {
                    if (!perfil) {
                        return Promise.reject("Perfil não encontrado");
                    }

                    return {
                        descricao: perfil.descricao,
                        ativo: perfil.ativo,
                        acoes: perfil.acoes,
                        idEscritorio: perfil.idEscritorio,
                        providencias: perfil.providencias,
                    } as InserirAtualizarPerfilCommand;
                })
            );
        }
    }, [id, novo]);

    return (
        <Loading promise={promiseCommand}>
            {(command) =>
                command ? (
                    <>
                        <div className="margin-bottom">
                            <Back />
                        </div>
                        <Formik
                            initialValues={command}
                            validationSchema={validationSchema}
                            onSubmit={async (values) => {
                                const novoId = await ActionFeedback.processing({
                                    title: `${novo ? "Incluindo" : "Atualizando"} perfil...`,
                                    execution: novo
                                        ? PerfilService.inserir(values)
                                        : PerfilService.atualizar(id, values),
                                });

                                if (novo) {
                                    history.replace(`/configuracoes/perfil/editar/${novoId}`);
                                } else {
                                    history.goBack();
                                }
                            }}
                        >
                            <FormPerfil idEscritorio={command.idEscritorio} novo={novo} idPerfil={id} />
                        </Formik>
                    </>
                ) : (
                    <>Erro carregando perfil</>
                )
            }
        </Loading>
    );
};

export const FormPerfil = ({
    idPerfil,
    novo,
    idEscritorio,
}: {
    novo: boolean;
    idPerfil: string;
    idEscritorio?: string;
}) => {
    const { values, setFieldValue } = useFormikContext<InserirAtualizarPerfilCommand>();
    const [promise, setPromise] = useState<Promise<ModuloViewModel | undefined>>();

    const readonly = !idEscritorio;

    useEffect(() => {
        setPromise(obterModulo());
    }, []);

    return (
        <Form>
            <OmnijusCard
                header={<h3>Perfil</h3>}
                body={
                    <>
                        {readonly && <p>Esse é um perfil global da plataforma e não pode ser editado.</p>}
                        <div className={styles.gridForm}>
                            <OmnijusTextField name="descricao" label="Descrição" disabled={readonly} />
                            <OmnijusCheckboxFieldSingle name="ativo" label="Ativo" disabled={readonly} />
                        </div>
                    </>
                }
            />
            <SpacerV />
            <OmnijusCard
                header={<h3>Providências</h3>}
                body={
                    <PerfilProvidencias
                        disabled={readonly}
                        idPerfil={idPerfil}
                        name={TypeUtils.nameof<InserirAtualizarPerfilCommand>("providencias")}
                    />
                }
            />
            <SpacerV />
            <div className={styles.containerFlex}>
                <div className={styles.moduloWrapper}>
                    <OmnijusCard
                        header={<h3>Funcionalidades</h3>}
                        body={
                            <Loading promise={promise}>
                                {(modulo) =>
                                    modulo ? (
                                        <TreeViewModulo
                                            modulo={modulo}
                                            checked={values.acoes}
                                            readonly={readonly}
                                            setChecked={(checkeds) => setFieldValue("acoes", checkeds)}
                                        />
                                    ) : (
                                        <p>Escolha o modulo para carregar as funcionalidades</p>
                                    )
                                }
                            </Loading>
                        }
                    />
                </div>
                {!novo && (
                    <div className={styles.usuariosWrapper}>
                        <UsuarioPerfil idPerfil={idPerfil} />
                    </div>
                )}
            </div>
            <SpacerV />
            {readonly || (
                <div className="text-right">
                    <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                </div>
            )}
        </Form>
    );
};

const TreeViewModulo = (props: {
    modulo: ModuloViewModel;
    checked: string[];
    setChecked: (checked: string[]) => void;
    readonly?: boolean;
}) => {
    const [expanded, setExpanded] = useState<string[]>([]);

    const nodes = useMemo(() => {
        if (!props.modulo.funcionalidades) {
            return [];
        }

        return props.modulo.funcionalidades.map((f) => ({
            label: f.descricao,
            value: f.tag,
            children: f.acoes.map((a) => ({
                label: a.descricao,
                value: a.tag,
            })),
        }));
    }, [props.modulo]);

    return (
        <CheckboxTree
            nodes={nodes}
            checked={props.checked}
            expanded={expanded}
            onCheck={props.setChecked}
            onExpand={setExpanded}
            showNodeIcon={false}
            disabled={props.readonly}
            icons={{
                check: <Icon path={mdiCheckboxOutline} size={1} />,
                uncheck: <Icon path={mdiCheckboxBlankOutline} size={1} />,
                halfCheck: <Icon path={mdiMinusBoxOutline} size={1} className="indeterminate" />,
                expandClose: <Icon path={mdiChevronRight} size={1} />,
                expandOpen: <Icon path={mdiChevronDown} size={1} />,
                expandAll: <Icon path={mdiExpandAllOutline} size={1} />,
                collapseAll: <Icon path={mdiCollapseAllOutline} size={1} />,
                parentClose: <Icon path={mdiCheck} size={1} />,
                parentOpen: <Icon path={mdiCheck} size={1} />,
                leaf: <Icon path={mdiCircleSmall} size={1} />,
            }}
        />
    );
};
