import { ControleAcesso, Error404, useControleAcesso } from "@omnijus/common";
import { ListarValidacoes } from "pages/validacoes/listar-validacoes";
import { Processos } from "pages/processos/processos";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RotaControleAcesso } from "shared/navigation/acessos-helper";
import { Navigation } from "./shared/navigation/navigation";
import { Perfil } from "pages/configuracoes/perfil/perfil";
import { Perfis } from "pages/configuracoes/perfil/perfis";
import { Usuario } from "pages/configuracoes/usuario/usuario";
import { Usuarios } from "pages/configuracoes/usuario/usuarios";
import { DocumentosPeticionamento } from "pages/validacoes/documentos-peticionamento";

const GetInitialRoute = (controleAcesso: ControleAcesso) => {
    if (controleAcesso.possuiAcessoFuncionalidade("validacoes")) {
        return "/validacoes";
    } else if (controleAcesso.possuiAcessoFuncionalidade("processos")) {
        return "/processos";
    }

    return "/";
};

export const Router = () => {
    const controleAcesso = useControleAcesso();

    return (
        <BrowserRouter>
            <Navigation />
            <div className="container">
                <Switch>
                    <RotaControleAcesso path="/validacoes/:id/documentos" tags={["validacoes"]}>
                        <DocumentosPeticionamento />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/validacoes" tags={["validacoes"]}>
                        <ListarValidacoes />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/processos" tags={["processos"]}>
                        <Processos />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/novo" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/editar/:id" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/detalhes/:id" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil" tags={["configuracao_perfil"]}>
                        <Perfis />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario/editar/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario/detalhes/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario" tags={["configuracao_usuario"]}>
                        <Usuarios />
                    </RotaControleAcesso>

                    <Route path="/">
                        <Redirect to={GetInitialRoute(controleAcesso)} />
                    </Route>

                    <Route path="*">
                        <Error404 />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
};
