import { mdiAccount, mdiAccountCancel } from "@mdi/js";
import Icon from "@mdi/react";
import {
    ActionFeedback,
    CardFiltros,
    Loading,
    OmnijusCard,
    OmnijusSelectField,
    OmnijusTextField,
    RequestApi,
} from "@omnijus/common";
import { parseUsuarioFilter, UsuarioFilter } from "lib/http/configuracoes/usuario/usuario-filter";
import { UsuarioService } from "lib/http/configuracoes/usuario/usuario-service";
import { UsuarioViewModel } from "lib/http/configuracoes/usuario/usuario-viewmodel";
import { PagedResult } from "lib/paged-result";
import { PaginationInfo } from "lib/pagination-info";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonOutlined } from "shared/buttons/button-outlined/button-outlined";
import { OmnijusFiltroPerfil } from "shared/form/custom-fields/omnijus-filtro-perfil";
import { AddNovoUsuario } from "./components/novo-usuario";
import styles from "./usuarios.module.scss";

export const Usuarios = () => {
    const history = useHistory();
    const query = useLocation().search;

    const [promise, setPromise] = useState<Promise<PagedResult<UsuarioViewModel> | undefined>>();
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        sortColumn: "",
        sortDirection: "asc",
        pageNumber: 1,
        rowsPerPage: 20,
    });

    const filtro = useMemo(() => parseUsuarioFilter(query), [query]);

    useEffect(() => {
        setPromise(UsuarioService.Listar(filtro, paginationInfo));
    }, [filtro, paginationInfo]);

    const buscar = useCallback(
        (filtros: UsuarioFilter) => {
            const search = RequestApi.objectToQueryString(filtros);
            history.replace({ search });
        },
        [history]
    );

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            rowsPerPage,
            pageNumber: 1,
        }));
    };

    const handleSort = (column: IDataTableColumn<any>, sortDirection: "asc" | "desc") => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            sortDirection,
            sortColumn: String(column.id),
            pageNumber: 1,
        }));
    };

    const handleChangePage = (page: number) => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            pageNumber: page,
        }));
    };

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const AtivarUsuario = async (idUsuario: string) => {
        const resposta = await ActionFeedback.confirm("Deseja ativar o usuário?");

        if (!resposta) {
            return;
        }

        await ActionFeedback.processing({
            title: "Ativando o usuário...",
            execution: UsuarioService.Ativar(idUsuario),
        });

        setPromise(UsuarioService.Listar(filtro, paginationInfo));
    };

    const InativarUsuario = async (idUsuario: string) => {
        const resposta = await ActionFeedback.confirm("Deseja inativar o usuário?");

        if (!resposta) {
            return;
        }

        await ActionFeedback.processing({
            title: "Inativando o usuário...",
            execution: UsuarioService.Inativar(idUsuario),
        });

        setPromise(UsuarioService.Listar(filtro, paginationInfo));
    };

    const columns: IDataTableColumn<UsuarioViewModel>[] = [
        {
            name: "Nome",
            selector: "nome",
            maxWidth: "25%",
            sortable: true,
            wrap: false,
        },
        {
            name: "CPF",
            selector: "cpf",
            maxWidth: "20%",
            sortable: true,
            wrap: false,
        },
        {
            name: "Email",
            maxWidth: "30%",
            selector: "email",
            sortable: true,
            wrap: false,
        },
        {
            name: "Status",
            selector: "status",
            maxWidth: "15%",
            sortable: true,
            wrap: false,
            cell: ({ ativo }) => (ativo ? "Ativo" : "Inativo"),
        },
        {
            name: "Ativar/Inativar",
            maxWidth: "20px",
            sortable: false,

            cell: (row) =>
                row.ativo === true ? (
                    <span onClick={async () => InativarUsuario(row.id)}>
                        <Icon className={styles.actionButton} path={mdiAccountCancel} size={1.5} />
                    </span>
                ) : (
                    <span onClick={async () => AtivarUsuario(row.id)}>
                        <Icon className={styles.actionButton} path={mdiAccount} size={1.5} />
                    </span>
                ),
        },
    ];

    return (
        <div>
            <h2>Usuários</h2>
            <div className={styles.cardFiltro}>
                <CardFiltros
                    textoBotao="Filtrar"
                    onBuscar={async (values) => {
                        buscar(values);
                    }}
                    initialValues={filtro}
                    botaoFiltrarInline
                >
                    <FormFiltroUsuario />
                </CardFiltros>
            </div>
            <Loading promise={promise}>
                {(pagedResult) => (
                    <OmnijusCard
                        body={
                            <>
                                <DataTable
                                    title={"Colaboradores"}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    actions={
                                        <ButtonOutlined
                                            onClick={() => {
                                                setModalIsOpen(true);
                                            }}
                                        >
                                            Novo Usuário
                                        </ButtonOutlined>
                                    }
                                    pagination
                                    paginationPerPage={paginationInfo.rowsPerPage}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={pagedResult?.results || []}
                                    paginationServer
                                    onChangeRowsPerPage={handleRowsPerPage}
                                    paginationDefaultPage={pagedResult?.pageNumber ?? 1}
                                    onChangePage={handleChangePage}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                    onSort={handleSort}
                                    sortServer
                                    defaultSortField={
                                        columns.find((c) => c.id === paginationInfo.sortColumn)?.selector as string
                                    }
                                    defaultSortAsc={paginationInfo.sortDirection === "asc"}
                                    paginationTotalRows={pagedResult?.total}
                                    onRowClicked={(row) => history.push(`/configuracoes/usuario/editar/${row.id}`)}
                                    columns={columns}
                                />
                            </>
                        }
                    />
                )}
            </Loading>
            <AddNovoUsuario isModalOpen={modalIsOpen} onCloseModal={() => setModalIsOpen(false)} />
        </div>
    );
};

export const FormFiltroUsuario = () => {
    return (
        <>
            <OmnijusTextField name="term" label="Descrição" />
            <OmnijusFiltroPerfil name="idPerfil" label="Perfil" />

            <OmnijusSelectField
                name={"status"}
                label={"Status"}
                options={[
                    {
                        value: "true",
                        label: "Ativo",
                    },
                    {
                        value: "false",
                        label: "Não ativo",
                    },
                ]}
            />
        </>
    );
};
