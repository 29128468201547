import { arrayObjectToCSV, TableHeader, workbookToBase64 } from "lib/export-format";
import { TriggerDownload } from "lib/trigger-download";
import React, { useEffect, useState } from "react";
import DataTable, {IDataTableColumn} from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { Exportar } from "shared/buttons/button-export-csv/button-export-csv";
import { Rotulo } from "shared/rotulo-movimentacao/rotulo-movimentacao";
import Swal from "sweetalert2";
import "./processos.scss";
import { RowDetalheProcesso } from "./row-detalhe-processo";
import { CreateWorkbook } from "./processo-excel-helper";
import { dateToDDMMYYYY, Loading, OmnijusCard, PromiseState } from "@omnijus/common";
import { FiltrosAplicadosProvider } from "shared/form/filtros-aplicados/filtros-aplicados-context";
import { FiltroAplicado, FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { FiltrosProcessos } from "shared/form/filtros/filtros-processo/filtros-processos";
import { DetalheProcesso } from 'lib/http/processo/models/detalhe-processo';
import { ProcessoService } from 'lib/http/processo/processo-service';

const detalheProcessoUrl = "/processos/detalhes/";

const dataTableColumns: IDataTableColumn<DetalheProcesso>[] = [
    {
        name: "Nº do processo",
        selector: (row: any) => row.numeroProcesso,
        sortable: true,
        minWidth: "15rem",
    },
    {
        name: "Última movimentação",
        selector: (row: any) => row.descricaoUltimoProcessoDocumento,
        sortable: true,
        maxWidth: "20rem",
    },
    {
        name: "Rótulos do Movimento",
        selector: (p: any) => (
            <>
                {p.rotulosUltimoMovimento?.map((mov: any) => (
                    <Rotulo key={mov.nome} {...mov} compacto />
                ))}
            </>
        ),
        maxWidth: "12rem",
        minWidth: "12rem",
    },
    {
        name: "Autor",
        selector: (row: any) => row.autor?.nome || "-",
        sortable: true,
        wrap: true,
    },
    {
        name: "Réu",
        selector: (row: any) => row.reu?.nome || "-",
        sortable: true,
    },
    {
        name: "Tipo de ação",
        selector: (row: any) => row.descricaoTipoAcao,
        sortable: true,
        wrap: true,
    },
    {
        name: "Comarca",
        selector: (row: any) => row.comarca,
        sortable: true,
    },
];

const downloadExportar = async (listaProcessos: DetalheProcesso[], type: "xlsx" | "csv") => {
    const headers: TableHeader[] = [
        { label: "Nº do processo", selector: "numeroProcesso", width: 25 },
        { label: "Última movimentação", selector: "descricaoUltimoProcessoDocumento", width: 128 },
        { label: "Autor", selector: "autor", width: 43 },
        { label: "Réu", selector: "reu", width: 32 },
        { label: "Tipo de ação", selector: "descricaoTipoAcao", width: 22 },
        { label: "Tribunal", selector: "tribunal", width: 14 },
        { label: "Comarca", selector: "comarca", width: 20 },
        { label: "Foro", selector: "foro", width: 20 },
        { label: "Vara", selector: "vara", width: 22 },
        { label: "Data da distribuição", selector: "dataHoraDistribuicao", width: 18 },
        { label: "Julgador", selector: "juiz", width: 29 },
        { label: "Assunto", selector: "assunto", width: 58 },
        { label: "Valor da ação", selector: "valorAcao", reais: true, width: 14 },
        { label: "Valor do risco", selector: "valorRisco", reais: true, width: 14 },
        { label: "Status", selector: "descricaoStatusProcesso", width: 10 },
    ];

    const data = listaProcessos.map((processo) => {
        const obj: any = {
            ...processo,
            autor: processo.autor?.nome,
            reu: processo.reu?.nome,
            assunto: processo.assunto?.join(),
            dataHoraDistribuicao: new Date(processo.dataHoraDistribuicao),
        };

        return obj;
    });
    if (type === "csv") {
        TriggerDownload.csv(
            await arrayObjectToCSV(data, headers),
            `relatorio-processos-${dateToDDMMYYYY(new Date())}.csv`
        );
    } else {
        // type === 'xlsx'
        TriggerDownload.xlsx(
            await workbookToBase64(CreateWorkbook(data, headers)),
            `relatorio-processos-${dateToDDMMYYYY(new Date())}.xlsx`
        );
    }
};

export const Processos = () => {
    const [promiseProcessos, setPromiseProcessos] = useState<Promise<DetalheProcesso[] | undefined>>();
    const [filtrosAplicados, setFiltrosAplicados] = useState<Array<FiltroAplicado>>([]);
    const history = useHistory();
    const query = useLocation().search;

    useEffect(() => {
        if (query) {
            setPromiseProcessos(ProcessoService.listarProcessos(new URLSearchParams(query)));
        }
    }, [query]);

    return (
        <div className="processos">
            <h2 className="titulo-destaque">Processos</h2>
            <FiltrosAplicadosProvider>
                <FiltrosProcessos
                    collapsedByDefault={Boolean(query)}
                    onBuscar={async (filtros, filtrosAplicados) => {
                        if (promiseProcessos) {
                            if (!(await PromiseState.isPending(promiseProcessos))) {
                                setPromiseProcessos(ProcessoService.listarProcessos(filtros));
                                setFiltrosAplicados(filtrosAplicados);
                            } else {
                                Swal.fire({
                                    icon: "warning",
                                    text: "Uma busca já está sendo realizada no momento! Aguarde...",
                                });
                            }
                        } else {
                            setPromiseProcessos(ProcessoService.listarProcessos(filtros));
                            setFiltrosAplicados(filtrosAplicados);
                        }
                    }}
                />
            </FiltrosAplicadosProvider>
            <OmnijusCard
                body={
                    promiseProcessos ? (
                        <Loading promise={promiseProcessos}>
                            {(listaProcessos) => (
                                <DataTable
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    title={
                                        <React.Fragment>
                                            <p>Processos</p>
                                            <FiltrosAplicados tags={filtrosAplicados} />
                                        </React.Fragment>
                                    }
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    actions={
                                        listaProcessos && listaProcessos.length > 0 ? (
                                            <>
                                                <label>Exportar: </label>
                                                <Exportar
                                                    onClick={() => downloadExportar(listaProcessos, "xlsx")}
                                                    texto="Excel (xlsx)"
                                                />
                                                <Exportar
                                                    onClick={() => downloadExportar(listaProcessos, "csv")}
                                                    texto="csv"
                                                />
                                            </>
                                        ) : null
                                    }
                                    columns={dataTableColumns}
                                    onRowClicked={(p) => history.push(detalheProcessoUrl + p.idProcesso, { replace: true })}
                                    data={listaProcessos || []}
                                    expandableRows={true}
                                    expandableRowsComponent={<RowDetalheProcesso />}
                                />
                            )}
                        </Loading>
                    ) : (
                        <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
                    )
                }
            />
        </div>
    );
};
