import {
  ActionFeedback,
  ButtonPrimary,
  ButtonSecondary,
  Loading,
  OmnijusCard,
} from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";
import { OmnijusFiltroCpfCnpj } from "shared/form/custom-fields/omnijus-filtro-cpf-cnpj";
import { SpacerV } from "shared/layout/spacer";
import { object as YupObject, string as YupString } from "yup";
import Modal from "react-modal";

import { FormUsuario } from "./form-usuario";
import { useHistory } from "react-router-dom";

import styles from "../usuarios.module.scss";
import { UsuarioCommand } from "lib/http/configuracoes/usuario/usuario-command";
import { UsuarioService } from "lib/http/configuracoes/usuario/usuario-service";
import { UsuarioViewModel } from "lib/http/configuracoes/usuario/usuario-viewmodel";

const validationSchema = YupObject().shape({
  cpf: YupString()
    .trim()
    .matches(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/, "CPF inválido")
    .required("É necessário informar o CPF"),
});

const validationSchemaNovoUsuario = YupObject().shape({
  nome: YupString()
    .required("É necessário informar o nome")
    .min(3, "O nome deve ter no mínimo 4 caracteres"),
  email: YupString()
    .required("É necessário informar o email")
    .email("O E-mail informado está inválido"),
  cpf: YupString().required("É necessário informar o CPF"),
  idPerfil: YupString().required("É necessário informar pelo menos um perfil"),
});

const FormNovoUsuario = (props: { onCloseModal: () => void }) => {
  const [promiseUsuario, setPromiseUsuario] = useState<
    Promise<UsuarioViewModel | undefined>
  >(Promise.resolve(undefined));
  const history = useHistory();

  const AddElement = () => (
    <ButtonPrimary type="submit">Associar</ButtonPrimary>
  );
  const GoToElement = (props: { id: String }) => (
    <ButtonPrimary
      onClick={() => history.push(`/configuracoes/usuario/editar/${props.id}`)}
    >
      Editar
    </ButtonPrimary>
  );

  return (
    <>
      <h2>Adicionar usuário</h2>
      <Formik
        onSubmit={async (values) => {
          var result = UsuarioService.ObterPorCpf(values.cpf);
          setPromiseUsuario(result);

          if (!(await result)) {
            setPromiseUsuario(
              Promise.resolve({
                cpf: values.cpf,
                nome: "",
                email: "",
              } as UsuarioViewModel)
            );
          }
        }}
        validationSchema={validationSchema}
        initialValues={{ cpf: "" }}
      >
        <Form>
          <OmnijusCard
            header={<h3>Pesquisar usuário</h3>}
            body={
              <div className={styles.gridForm}>
                <div className={styles.search}>
                  <OmnijusFiltroCpfCnpj name="cpf" label="CPF" />
                  <button type="submit">
                    <SearchIcon />
                  </button>
                </div>
              </div>
            }
          />
          <SpacerV />

          <div className="text-right">
            <ButtonSecondary
              className={styles.marginTop}
              onClick={props.onCloseModal}
            >
              Fechar
            </ButtonSecondary>
          </div>
        </Form>
      </Formik>

      <SpacerV />

      <Loading promise={promiseUsuario}>
        {(usuario) => {
          if (usuario) {
            return (
              <Formik
                validationSchema={validationSchemaNovoUsuario}
                initialValues={{
                  ...usuario,
                  idPerfil: "",
                  idEscritorio: "",
                  idModulo: "",
                }}
                onSubmit={async (values) => {
                  if (!values) {
                    return;
                  }

                  let usuario = {
                    cpf: values.cpf,
                    email: values.email,
                    nome: values.nome,
                    idPerfil: values.idPerfil,
                  } as UsuarioCommand;

                  console.log(usuario);

                  let result = await ActionFeedback.processing({
                    title: `Incluindo usuario...`,
                    execution: UsuarioService.Salvar(usuario),
                  });

                  if (result?.id) {
                    history.replace(
                      `/configuracoes/usuario/editar/${result?.id}`
                    );
                  }
                }}
              >
                <FormUsuario
                  isNew={usuario?.id == null}
                  action={
                    usuario?.id == null
                      ? AddElement
                      : () => GoToElement({ id: usuario.id })
                  }
                />
              </Formik>
            );
          }

          return <></>;
        }}
      </Loading>
    </>
  );
};

interface ModalCancelamentoAudienciaProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const AddNovoUsuario = (props: ModalCancelamentoAudienciaProps) => (
  <Modal
    style={{
      content: {
        width: "80vw",
        height: "auto",
        margin: "auto",
      },
    }}
    isOpen={props.isModalOpen}
    onRequestClose={props.onCloseModal}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick={false}
  >
    <FormNovoUsuario onCloseModal={props.onCloseModal} />
  </Modal>
);
