import { NomesTiposParametros, RequestApi } from "@omnijus/common";

export interface PerfilFilter {
    descricao?: string;
    statusAtivo?: boolean;
    idCiente?: string;
    idEscritorio?: string;
    idModulo?: string;
    relacionadoEscritorio?: boolean;
}

const tiposParametros: NomesTiposParametros<Required<PerfilFilter>> = {
    descricao: "string",
    statusAtivo: "boolean",
    idCiente: "string",
    idEscritorio: "string",
    idModulo: "string",
    relacionadoEscritorio: "boolean"
};

export const parsePerfilFilter = (queryString: string): PerfilFilter =>
    RequestApi.parseQueryString(queryString, tiposParametros);
