export const TriggerDownload = {
    csv: (csv: string, fileName: string) => {
        if (csv) {
            const link = document.createElement("a");

            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }

            link.setAttribute("href", encodeURI(csv));
            link.setAttribute("download", fileName);
            link.click();
        }
    },
    xlsx: (xlsxBase64: string, fileName: string) => {
        if (xlsxBase64) {
            const link = document.createElement("a");

            if (!xlsxBase64.match(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64/i)) {
                xlsxBase64 = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsxBase64}`;
            }

            link.setAttribute("href", xlsxBase64);
            link.setAttribute("download", fileName);
            link.click();
        }
    },
};
