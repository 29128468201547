import { RequestApi } from "@omnijus/common";
import { EscritorioRequestApi } from "lib/http/escritorio-request-api";
import { PagedResult } from "lib/paged-result";
import { PaginationInfo } from "lib/pagination-info";
import { PerfilFilter } from "./perfil-filter";
import {
    AdicionarUsuarioPerfilCommand,
    InserirAtualizarPerfilCommand,
    PerfilViewModel,
    UsuarioPerfilViewModel,
} from "./perfil-viewmodel";

const perfilUrl = process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api";

export const PerfilService = {
    listar: async (filtro?: PerfilFilter, paginationInfo?: PaginationInfo) => {
        const url = new URL(`${perfilUrl}/v1/Perfil`);
        url.search = RequestApi.objectToQueryString({
            ...filtro,
            ...paginationInfo,
        });
        return await EscritorioRequestApi.get<PagedResult<PerfilViewModel>>(url.toString());
    },
    listarPerfisPorUsuario: async (usuarioId: String) => {
        const url = new URL(`${perfilUrl}/v1/Perfil/ListarPorUsuario/${usuarioId}`);
        return await EscritorioRequestApi.get<UsuarioPerfilViewModel[]>(url.toString());
    },
    obter: async (id: string) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await EscritorioRequestApi.get<PerfilViewModel>(url);
    },
    inserir: async (command: InserirAtualizarPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil`;
        return await EscritorioRequestApi.post<string>(url, command);
    },
    atualizar: async (id: string, command: InserirAtualizarPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await EscritorioRequestApi.put<string>(url, command);
    },
    excluir: async (id: string) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await EscritorioRequestApi.delete<string>(url);
    },
    listarUsuarios: async (idPerfil: string) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios`;
        return await EscritorioRequestApi.get<UsuarioPerfilViewModel[]>(url);
    },
    adicionarUsuario: async (idPerfil: string, command: AdicionarUsuarioPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios`;
        await EscritorioRequestApi.post(url, command);
    },
    removerUsuario: async (idPerfil: string, idPerfilUsuario: string) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios/${idPerfilUsuario}`;
        await EscritorioRequestApi.delete(url);
    },
    copiar: async (idPerfil: string, novoNome?: string) => {
        const url = new URL(`${perfilUrl}/v1/Perfil/${idPerfil}/Copiar`);
        if (novoNome) {
            url.searchParams.set("novoNome", novoNome);
        }
        await EscritorioRequestApi.post(url.toString());
    },
};
