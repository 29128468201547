import { Format } from "lib/format";
import { EscritorioRequestApi } from "../escritorio-request-api";
import { FiltroAlegacao } from "./models/filtro-alegacao";
import { FiltroArea } from "./models/filtro-area";
import { FiltroClasse } from "./models/filtro-classe";
import { FiltroComarca } from "./models/filtro-comarca";
import { FiltroEstado } from "./models/filtro-estado";
import { FiltroFase } from "./models/filtro-fase";
import { FiltroMomento } from "./models/filtro-momento";
import { FiltroOrgao } from "./models/filtro-orgao";
import { FiltroOrigemFalha } from "./models/filtro-origem-falha";
import { FiltroPedido } from "./models/filtro-pedido";
import { FiltroRito } from "./models/filtro-rito";
import { FiltroSecao } from "./models/filtro-secao";
import { FiltroStatus } from "./models/filtro-status";
import { FiltroTipoResultadoSentenca } from "./models/filtro-tipo-resultado-sentenca";

const processoFiltroUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/Dominio/v1/Filtro";

export const ProcessoFiltroService = {
  listarArea: async () => {
    let url = processoFiltroUrl + "/ListarArea";
    return await EscritorioRequestApi.get<FiltroArea[]>(url);
  },
  listarOrgao: async (idArea?: number, idEstado?: string) => {
    let url = processoFiltroUrl + "/ListarOrgaoIdAreaUF";
    let params = [];

    if (idArea) {
      params.push(`idArea=${idArea}`);
    }
    if (idEstado) {
      params.push(`uf=${idEstado}`);
    }

    return await EscritorioRequestApi.get<FiltroOrgao[]>(
      url + Format.arrayParamsToQueryString(params)
    );
  },
  listarForo: async (idOrgao?: string, nomeComarca?: string) => {
    let url = processoFiltroUrl + "/ListarForoIdOrgaoNomeComarca";
    let params = [];

    if (idOrgao) {
      params.push(`idOrgao=${idOrgao}`);
    }
    if (nomeComarca) {
      params.push(`nomeComarca=${nomeComarca}`);
    }

    return await EscritorioRequestApi.get<FiltroOrgao[]>(
      url + Format.arrayParamsToQueryString(params)
    );
  },
  listarComarca: async (idOrgao?: string) => {
    let url =
      processoFiltroUrl +
      "/ListarComarcaIdOrgao" +
      (idOrgao ? `?idOrgao=${idOrgao}` : "");
    return await EscritorioRequestApi.get<FiltroComarca[]>(url);
  },
  listarSecao: async (idUnidade?: string) => {
    let url =
      processoFiltroUrl +
      "/ListarSecaoIdUnidade" +
      (idUnidade ? `?idUnidade=${idUnidade}` : "");
    return await EscritorioRequestApi.get<FiltroSecao[]>(url);
  },
  listarEstado: async () => {
    let url = processoFiltroUrl + "/ListarUF";
    return await EscritorioRequestApi.get<FiltroEstado[]>(url);
  },
  listarRito: async (idArea?: number) => {
    let url =
      processoFiltroUrl +
      "/ListarRitoPorIdArea" +
      (idArea ? `?idArea=${idArea}` : "");
    return await EscritorioRequestApi.get<FiltroRito[]>(url);
  },
  listarFase: async (idRito?: number) => {
    let url =
      processoFiltroUrl +
      "/ListarFaseProcessoPorIdRitoProcesso" +
      (idRito ? `?idRitoProcesso=${idRito}` : "");
    return await EscritorioRequestApi.get<FiltroFase[]>(url);
  },
  listarClasse: async (idRito?: number) => {
    let url =
      processoFiltroUrl +
      "/ListarClasseProcessoPorIdRitoProcesso" +
      (idRito ? `?idRitoProcesso=${idRito}` : "");
    return await EscritorioRequestApi.get<FiltroClasse[]>(url);
  },
  listarMomento: async (idRito?: number, idFase?: number) => {
    let url =
      processoFiltroUrl +
      "/ListarMomentoProcessoPorIdRitoProcessoIdFaseProcesso";
    let params = [];

    if (idRito) {
      params.push(`idRitoProcesso=${idRito}`);
    }
    if (idFase) {
      params.push(`idFaseProcesso=${idFase}`);
    }

    return await EscritorioRequestApi.get<FiltroMomento[]>(
      url + Format.arrayParamsToQueryString(params)
    );
  },
  listarAlegacao: async (idRito?: number) => {
    let url =
      processoFiltroUrl +
      "/ListarAlegacaoMeritoPorIdRitoProcesso" +
      (idRito ? `?idRitoProcesso=${idRito}` : "");
    return await EscritorioRequestApi.get<FiltroAlegacao[]>(url);
  },
  listarStatus: async () => {
    let url = processoFiltroUrl + "/ListarStatusProcesso";
    return await EscritorioRequestApi.get<FiltroStatus[]>(url);
  },
  listarTipoPedido: async () => {
    let url = processoFiltroUrl + "/TipoPedidoProcesso";
    return await EscritorioRequestApi.get<FiltroPedido[]>(url);
  },
  listarTipoResultadoSentenca: async () => {
    let url = processoFiltroUrl + "/ListarTipoResultadoSentenca";
    return await EscritorioRequestApi.get<FiltroTipoResultadoSentenca[]>(url);
  },
  listarOrigemFalha: async () => {
    let url = processoFiltroUrl + "/ListarOrigemFalha";
    return await EscritorioRequestApi.get<FiltroOrigemFalha[]>(url);
  },
};
