import React from "react";
import { OmnijusTextField } from "../fields/omnijus-text-field";

interface OmnijusFiltroOabProps {
    hideLabel?: boolean;
    name: string;
    label: string;
}

export const OmnijusFiltroOab = (props: OmnijusFiltroOabProps) => {
    return (
        <OmnijusTextField
            name={props.name}
            label={props.hideLabel ? undefined : props.label}
            placeholder="UF 999999"
            masks={[{ mask: "## ######", case: "uppercase" }]}
        />
    );
};
