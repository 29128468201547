import { useControleAcesso, WrapperAcessoAcao, WrapperAcessoFuncionalidade } from "@omnijus/common";
import classNames from "classnames";
import React from "react";
import { PropsWithChildren } from "react";
import { NavLink, Route } from "react-router-dom";
import styles from "./navigation.module.scss";

export type TagsFuncionalidades =
    | "validacoes"
    | "processos"
    | "dashboard"
    | "configuracao_perfil"
    | "configuracao_usuario";

export type TagsAcoes =
    | "processos.somente_leitura"
    | "dashboard.acesso_total"
    | "validacoes.download"
    | "validacoes.remover_arquivo"
    | "validacoes.aprovar"
    | "validacoes.aprovar_com_ressalva"
    | "validacoes.reprovar";

export const WrapperAcessoFuncionalidadeEscritorio = (props: PropsWithChildren<{ tags: TagsFuncionalidades[] }>) => (
    <WrapperAcessoFuncionalidade {...props} />
);
export const WrapperAcessoAcaoEscritorio = (props: PropsWithChildren<{ tags: TagsAcoes[] }>) => (
    <WrapperAcessoAcao {...props} />
);

export function RotaControleAcesso(props: PropsWithChildren<{ path: string; tags: TagsFuncionalidades[] }>) {
    const controleAcesso = useControleAcesso();

    if (controleAcesso.possuiAcessoFuncionalidade(...props.tags)) {
        return (
            <Route exact path={props.path}>
                {props.children}
            </Route>
        );
    }

    return <p style={{ textAlign: "center" }}>Acesso negado</p>;
}

export function NavLinkControleAcesso(
    props: PropsWithChildren<{
        exact?: boolean;
        to: string;
        tags: TagsFuncionalidades[];
        className?: string;
    }>
) {
    return (
        <WrapperAcessoFuncionalidade tags={props.tags}>
            <NavLink
                className={(isActive) => classNames(props.className || styles.navLink, { [styles.active]: isActive })}
                to={props.to}
                exact={props.exact}
            >
                {props.children}
            </NavLink>
        </WrapperAcessoFuncionalidade>
    );
}
