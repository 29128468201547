import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { Dropdown, OmnijusLogo, useAuth } from "@omnijus/common";
import { clearSessionIdEscritorio } from "lib/http/escritorio-request-api";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useEscritorio } from "selecionar-escritorio/escritorio-context";
import { NavLinkControleAcesso, WrapperAcessoFuncionalidadeEscritorio } from "shared/navigation/acessos-helper";
import styles from "./navigation.module.scss";

export const Navigation = () => {
    const auth = useAuth();
    const { escritorio, escritorios } = useEscritorio();

    const userOptions = useMemo(() => {
        const options = [];

        if (escritorios.length > 1) {
            options.push({
                label: "Trocar escritório",
                onClick: () => {
                    clearSessionIdEscritorio();
                    window.location.reload();
                },
            });
        }

        options.push({
            label: "Dados pessoais",
            onClick: () => window.open(`${process.env.REACT_APP_AUTH_URL}`),
        });

        options.push({
            label: "Sair",
            onClick: () => auth?.userManager.signoutRedirect(),
        });

        return options;
    }, [escritorios, auth]);

    return (
        <nav className={styles.navBar}>
            <Link className={styles.navLinkDashboard} to="/">
                <OmnijusLogo />
            </Link>

            <div className={styles.navItems}>
                <NavLinkControleAcesso tags={["validacoes"]} to="/validacoes" exact>
                    Validações
                </NavLinkControleAcesso>

                <NavLinkControleAcesso tags={["processos"]} to="/processos" exact>
                    Processos
                </NavLinkControleAcesso>

                <WrapperAcessoFuncionalidadeEscritorio tags={["configuracao_perfil", "configuracao_usuario"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        className={styles.link}
                                        exact
                                        to="/configuracoes/perfil"
                                        tags={["configuracao_perfil"]}
                                    >
                                        Perfil
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        className={styles.link}
                                        exact
                                        to="/configuracoes/usuario"
                                        tags={["configuracao_usuario"]}
                                    >
                                        Usuário
                                    </NavLinkControleAcesso>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Configurações</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeEscritorio>
            </div>

            <div className={styles.navRight}>
                <div className={styles.item}>
                    <Dropdown options={userOptions}>
                        <div className={styles.perfil}>
                            <div className={styles.user}>
                                {String(auth?.user?.profile.name ?? auth?.user?.profile.email).charAt(0)}
                            </div>

                            <div className={styles.userName}>
                                <h4>{auth?.user?.profile.name}</h4>
                                <p>{escritorio?.nome}</p>
                            </div>

                            <div className={styles.dropIcon}>
                                <Icon path={mdiChevronDown} size={1} />
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};
