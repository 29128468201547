import { dateToDDMMYYYY, formatDateAndTime } from "@omnijus/common";
import ExcelJS from "exceljs";
import { IDataTableColumn } from "react-data-table-component";

export interface TableHeader {
    label: string;
    selector: string;
    width?: number;
    wrapText?: boolean;
    reais?: boolean;
    format?: (v: string) => string;
}

export const xlsxToArray = (file: File) => {
    return new Promise<ExcelJS.CellValue[][]>((resolve, reject) => {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result as ArrayBuffer;
            workbook.xlsx.load(buffer).then((wb) => {
                const sheet = wb.worksheets[0];
                const { rowCount } = sheet;
                const rows =
                    sheet.getRows(1, rowCount)?.map((row) => {
                        const { cellCount } = row;
                        const cells = [];
                        for (let i = 1; i <= cellCount; i++) {
                            cells.push(row.getCell(i).value);
                        }
                        return cells;
                    }) || [];
                resolve(rows);
            });
        };
        reader.onerror = (er) => reject(er);
    });
};

export const objectArrayToCSV = <T extends object>(data: Array<T>, headers: Array<TableHeader>) => {
    let result = "\ufeff";

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    /** escapa caracteres especiais para o csv em strings  */
    const adjustCell = (cell: any): string => {
        if (typeof cell === "string") {
            return '"' + cell.replace(/"/g, '""') + '"';
        } else if (cell instanceof Date) {
            return formatDateAndTime(cell);
        } else if (typeof cell === "object") {
            return adjustCell(JSON.stringify(cell));
        } else {
            return adjustCell(String(cell));
        }
    };

    if (data.length > 0) {
        result += headers.map((h) => adjustCell(h.label)).join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            const fields: string[] = [];

            headers.forEach((h) => {
                const f = h.format || ((x) => x);
                fields.push(f(Object.entries(item).find((e) => e[0] === h.selector)?.[1]) || "");
            });

            result += fields.map(adjustCell).join(",");
            result += lineDelimiter;
        });
    }

    return result;
};

export const objectArrayToXLSX = async <T extends object>(data: Array<T>, headers: Array<TableHeader>) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Planilha");

    headers.forEach((h, index) => {
        const col = sheet.getColumn(index + 1);
        col.header = h.label;
        col.width = h.width || h.label.length;
        col.key = h.selector;
        col.style.alignment = { wrapText: h.wrapText, vertical: "top" };
        if (h.reais) {
            col.numFmt = "[$R$-pt-BR] #,##0.00";
        }
    });

    data.forEach((row) => {
        sheet.addRow(row);
    });

    sheet.getRow(1).font = { bold: true };

    let binary = "";
    const bytes = new Uint8Array(await workbook.xlsx.writeBuffer());
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const arrayObjectToXLSX = async <T extends object>(data: Array<T>, headers: IDataTableColumn<T>[]) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Planilha");

    const dataHeaders = headers.map((column) => {
        return {
            label: column.name?.toString() ?? "",
            width: column?.minWidth ? Number.parseFloat(column?.minWidth.replace(/[^\d.-]/g, "")) * 3 : 15,
            wrapText: false,
            reais: false,
        };
    });

    const rows = data.map((r, i) => {
        return headers.map((c) => {
            if (c.format) {
                return c.format(r, i);
            }
            if (typeof c.selector === "string") {
                return (r as any)[c.selector];
            } else if (c.selector != null) {
                return c.selector(r, i);
            }
            return "";
        });
    });

    dataHeaders.forEach((h, index) => {
        const col = sheet.getColumn(index + 1);
        col.header = h.label;
        col.width = h.width || h.label.length;
        col.style.alignment = { wrapText: h.wrapText, vertical: "top" };
        if (h.reais) {
            col.numFmt = "[$R$-pt-BR] #,##0.00";
        }
    });

    rows.forEach((row) => {
        sheet.addRow(row);
    });

    sheet.getRow(1).font = { bold: true };

    let binary = "";
    const bytes = new Uint8Array(await workbook.xlsx.writeBuffer());
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const arrayObjectToCSV = <T extends object>(
    data: Array<T>,
    headers: Array<{ label: string; selector: string }>
) => {
    let result = "\ufeff";

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    /** escapa caracteres especiais para o csv em strings  */
    const adjustCell = (cell: any): string => {
        if (typeof cell === "string") {
            return '"' + cell.replace(/"/g, '""') + '"';
        } else if (cell instanceof Date) {
            return dateToDDMMYYYY(cell);
        } else if (typeof cell === "object") {
            return adjustCell(JSON.stringify(cell));
        } else {
            return adjustCell(String(cell));
        }
    };

    if (data.length > 0) {
        result += headers.map((h) => adjustCell(h.label)).join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            let fields: string[] = [];

            headers.forEach((h) => {
                fields.push(Object.entries(item).find((e) => e[0] === h.selector)?.[1] || "");
            });

            result += fields.map(adjustCell).join(",");
            result += lineDelimiter;
        });
    }

    return result;
};

export const workbookToBase64 = async (workbook: ExcelJS.Workbook) => {
    let binary = "";
    const bytes = new Uint8Array(await workbook.xlsx.writeBuffer());
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};
