import {
    ActionFeedback,
    CardFiltros,
    dateToDDMMYYYY,
    Loading,
    OmnijusCard,
    OmnijusRangeDateField,
    OmnijusTextField,
    TextButton,
} from "@omnijus/common";
import { ReactComponent as DocumentIcon } from "assets/images/document-icon.svg";
import { format } from "date-fns";
import { arrayObjectToXLSX } from "lib/export-format";
import { SolicitacaoPeticionamentoListada } from "lib/http/peticionamento/models/solicitacao-peticionamento-listada";
import {
    downloadDocumentoProtocoloPeticionamento,
    listarPeticionamentos,
    ListarPeticionamentosParametros,
} from "lib/http/peticionamento/peticionamento-service";
import { TriggerDownload } from "lib/trigger-download";
import React, { useEffect, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { OmnijusCampoNumeroProcesso } from "shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusCampoStatusPeticionamento } from "shared/form/custom-fields/omnijus-campo-status-peticionamento";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroProvidencia } from "shared/form/custom-fields/omnijus-filtro-providencia";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "./listar-validacoes.module.scss";

const columns: IDataTableColumn<SolicitacaoPeticionamentoListada>[] = [
    {
        name: "Número do Processo",
        selector: (p: any) => p.numeroProcesso,
        sortable: true,
    },
    {
        name: "Data de distribuição",
        selector: (p: any) => p.dataDistribuicao,
        format: (p: any) => (p.dataDistribuicao ? dateToDDMMYYYY(p.dataDistribuicao) : ""),
        sortable: true,
    },
    {
        name: "Data prevista do peticionamento",
        selector: (p: any) => p.dataPrevistaPeticionamento,
        format: (p: any) => (p.dataPrevistaPeticionamento ? dateToDDMMYYYY(p.dataPrevistaPeticionamento) : ""),
        sortable: true,
    },
    {
        name: "Cliente",
        selector: (p: any) => p.nomeCliente?.toUpperCase(),
        sortable: true,
    },
    {
        name: "Status",
        selector: (p: any) => p.statusSolicitacaoPeticionamento,
        sortable: true,
    },
];

const downloadExportar = async (peticionamentos: SolicitacaoPeticionamentoListada[]) => {
    const columnsForExcel: IDataTableColumn<any>[] = [...columns];

    TriggerDownload.xlsx(
        await arrayObjectToXLSX(peticionamentos, columnsForExcel),
        `relatorio-peticionamentos-${format(new Date(), "yyyyMMddHHmmss")}.xlsx`
    );
};

export const ListarValidacoes = () => {
    const history = useHistory();

    const [promisePeticionamentos, setPromisePeticionamentos] =
        useState<Promise<SolicitacaoPeticionamentoListada[] | undefined>>();

    useEffect(() => {
        setPromisePeticionamentos(
            listarPeticionamentos({
                IdStatusPeticionamento: 1,
            })
        );
    }, []);

    return (
        <div className={styles.listarPeticionamentos}>
            <h2>Validações</h2>
            <CardFiltros
                onBuscar={(values) => {
                    const parametros: ListarPeticionamentosParametros = {
                        IdCliente: values.cliente,
                        IdStatusPeticionamento: parseInt(values.status) || undefined,
                        DataSolicitacaoFinal: values.dataAte,
                        DataSolicitacaoInicial: values.dataDe,
                        numeroProcesso: values.numeroProcesso,
                        idProvidencia: values.idProvidencia,
                        nomeUserAprovacaoReprovacao: values.nomeUserAprovacaoReprovacao,
                        dataHoraAprovacaoReprovacaoInicial: values.dataAprovacaoReprovacaoDe,
                        dataHoraAprovacaoReprovacaoFinal: values.dataAprovacaoReprovacaoAte,
                    };
                    setPromisePeticionamentos(listarPeticionamentos(parametros));
                }}
                initialValues={{
                    status: "1",
                    cliente: "",
                    dataDe: undefined as Date | undefined,
                    dataAte: undefined as Date | undefined,
                    numeroProcesso: "",
                    idProvidencia: undefined,
                    dataAprovacaoReprovacaoDe: undefined as Date | undefined,
                    dataAprovacaoReprovacaoAte: undefined as Date | undefined,
                    nomeUserAprovacaoReprovacao: "",
                }}
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    dataDe: YupDate().typeError("Data inválida").nullable(),
                    dataAte: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .when(["dataDe"], (di: Date, schema: any) => {
                            return di ? schema.min(di, "A data de final não pode ser anterior à data inicial") : schema;
                        }),
                    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                    dataAprovacaoReprovacaoDe: YupDate().typeError("Data inválida").nullable(),
                    dataAprovacaoReprovacaoAte: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .when(["dataAprovacaoReprovacaoDe"], (di: Date, schema: any) => {
                            return di ? schema.min(di, "A data de final não pode ser anterior à data inicial") : schema;
                        }),
                })}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusCampoStatusPeticionamento name="status" label="Status" />
                <OmnijusFiltroCliente name="cliente" label="Cliente" />
                <OmnijusRangeDateField nameDe="dataDe" nameAte="dataAte" label="Data Prevista do Peticionamento" />
                <OmnijusFiltroProvidencia label="Providência" />
                <OmnijusTextField name="nomeUserAprovacaoReprovacao" label="Usuário Aprovação / Reprovação" />
                <OmnijusRangeDateField
                    nameDe="dataAprovacaoReprovacaoDe"
                    nameAte="dataAprovacaoReprovacaoAte"
                    label="Data Aprovação / Reprovação"
                />
            </CardFiltros>

            <div className={styles.barraAcao}>
                <div className={styles.botoesAcao}>
                    <button
                        className={styles.botaoAcao}
                        onClick={async () => {
                            if (promisePeticionamentos) {
                                const peticionamentos = await ActionFeedback.processing({
                                    title: "Buscando registros...",
                                    execution: promisePeticionamentos,
                                });

                                if (peticionamentos) {
                                    downloadExportar(peticionamentos || []);
                                }
                            }
                        }}
                    >
                        Exportar para Excel (xlsx)
                    </button>
                    {/* <button
                        className={styles.botaoAcao}
                        onClick={() => {
                            history.push("/peticionamentos/aprovar");
                        }}
                    >
                        Aprovar
                    </button>
                    <button
                        className={styles.botaoAcao}
                        onClick={() => {
                            history.push("/peticionamentos/aprovar-ressalva");
                        }}
                    >
                        Aprovar com ressalva
                    </button> 
                    <button
                        className={styles.botaoAcao}
                        onClick={() => {
                            history.push("/peticionamentos/reprovar");
                        }}
                    >
                        Reprovar
                    </button> */}
                </div>
            </div>

            <Loading promise={promisePeticionamentos}>
                {(peticionamentos) => (
                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                data={peticionamentos || []}
                                columns={[
                                    {
                                        name: "Número do processo",
                                        selector: (peticionamento) => peticionamento.numeroProcesso,
                                        width: "15rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Data de distribuição",
                                        selector: (row) => row.dataDistribuicao,
                                        format: (row) => dateToDDMMYYYY(row.dataDistribuicao),
                                        width: "9rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Data prevista do peticionamento",
                                        selector: (row) => row.dataPrevistaPeticionamento,
                                        format: (row) => dateToDDMMYYYY(row.dataPrevistaPeticionamento),
                                        width: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Cliente",
                                        selector: (row) => row.nomeCliente,
                                        sortable: true,
                                        minWidth: "15rem",
                                        wrap: true,
                                    },
                                    {
                                        name: "Status",
                                        selector: (row) => row.statusSolicitacaoPeticionamento,
                                        sortable: true,
                                        minWidth: "12rem",
                                        wrap: true,
                                    },

                                    {
                                        name: "Providência",
                                        selector: (row) => row.descricaoProvidencia || "-",
                                        minWidth: "15rem",
                                        sortable: true,
                                        wrap: true,
                                    },
                                    {
                                        name: "",
                                        selector: (row) => (
                                            <DocumentIcon
                                                title="Visualizar documentos"
                                                width="2rem"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    history.push(
                                                        `validacoes/${row.idSolicitacaoPeticionamento}/documentos`
                                                    );
                                                }}
                                            />
                                        ),
                                        width: "5rem",
                                    },
                                    {
                                        name: "Recibo",
                                        selector: (row) =>
                                            row.idDocumentoProtocolo ? (
                                                <TextButton
                                                    onClick={async () => {
                                                        await ActionFeedback.processing({
                                                            title: "Baixando recibo",
                                                            execution: downloadDocumentoProtocoloPeticionamento(
                                                                row.idDocumentoProtocolo
                                                            ),
                                                            onError: async (error: any) =>
                                                                await ActionFeedback.error({
                                                                    icon: "error",
                                                                    html: error?.json?.erros
                                                                        ? error.json.erros.join("<br />")
                                                                        : error?.json?.title
                                                                        ? error?.json?.title
                                                                        : "Não foi possível baixar o recibo",
                                                                }),
                                                        });
                                                    }}
                                                >
                                                    Recibo
                                                </TextButton>
                                            ) : (
                                                "-"
                                            ),
                                        width: "7rem",
                                        button: true,
                                    },
                                    {
                                        name: "Usuário Aprovação / Reprovação",
                                        selector: (row) => row.nomeUserAprovacaoReprovacao || "-",
                                        width: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Data Aprovação / Reprovação",
                                        selector: (row) => row.dataHoraAprovacaoReprovacao,
                                        format: (row) =>
                                            row.dataHoraAprovacaoReprovacao &&
                                            dateToDDMMYYYY(new Date(row.dataHoraAprovacaoReprovacao)),
                                        width: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Envio Tácito",
                                        selector: (row) => row.envioTacito,
                                        format: (row) =>
                                            row.envioTacito === null ? "-" : row.envioTacito === true ? "Sim" : "Não",
                                        width: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Usuário validação",
                                        selector: (row) => row.nomeUsuarioValidacao || "-",
                                        sortable: true,
                                    },
                                    // {
                                    //     button: true,
                                    //     name: "Aprovar",
                                    //     selector: (row) =>
                                    //         row.idStatusSolicitacaoPeticionamento ===
                                    //             IdStatusPeticionamento.PendenteAprovacao && row.possuiDocumentos ? (
                                    //             <TextButton
                                    //                 onClick={async () => {
                                    //                     await ActionFeedback.processing({
                                    //                         title: "Aprovando solicitação de peticionamento",
                                    //                         execution: responderSolicitacaoPeticionamento(
                                    //                             row.idSolicitacaoPeticionamento,
                                    //                             true
                                    //                         ),
                                    //                         onError: async (error: any) =>
                                    //                             await ActionFeedback.error({
                                    //                                 icon: "error",
                                    //                                 html: error?.json?.erros
                                    //                                     ? error.json.erros.join("<br />")
                                    //                                     : error?.json?.title
                                    //                                     ? error?.json?.title
                                    //                                     : "",
                                    //                             }),
                                    //                     });

                                    //                     await ActionFeedback.info({
                                    //                         title: "Solicitação de peticionamento aprovada",
                                    //                     });
                                    //                 }}
                                    //             >
                                    //                 Aprovar
                                    //             </TextButton>
                                    //         ) : (
                                    //             "-"
                                    //         ),
                                    //     width: "7rem",
                                    // },
                                    // {
                                    //     button: true,
                                    //     name: "Reprovar",
                                    //     selector: (row) =>
                                    //         row.idStatusSolicitacaoPeticionamento ===
                                    //         IdStatusPeticionamento.PendenteAprovacao ? (
                                    //             <TextButton
                                    //                 onClick={async () => {
                                    //                     await ActionFeedback.processing({
                                    //                         title: "Reprovando solicitação de peticionamento",
                                    //                         execution: responderSolicitacaoPeticionamento(
                                    //                             row.idSolicitacaoPeticionamento,
                                    //                             false
                                    //                         ),
                                    //                         onError: async (error: any) =>
                                    //                             await ActionFeedback.error({
                                    //                                 icon: "error",
                                    //                                 html: error?.json?.erros
                                    //                                     ? error.json.erros.join("<br />")
                                    //                                     : error?.json?.title
                                    //                                     ? error?.json?.title
                                    //                                     : "",
                                    //                             }),
                                    //                     });

                                    //                     await ActionFeedback.info({
                                    //                         title: "Solicitação de peticionamento reprovada",
                                    //                     });
                                    //                 }}
                                    //             >
                                    //                 Reprovar
                                    //             </TextButton>
                                    //         ) : (
                                    //             "-"
                                    //         ),
                                    //     width: "7rem",
                                    // },
                                ]}
                            />
                        }
                    />
                )}
            </Loading>
        </div>
    );
};
