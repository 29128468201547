import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../lib/http/filtros/filtros-processo-service";
import { FiltroClasse } from "../../../lib/http/filtros/models/filtro-classe";
import { OmnijusSelectField } from "@omnijus/common";

const filtroRitoName = "IdRitoProcesso";

interface OmnijusFiltroTipoAcaoProps {
  hideLabel?: boolean;
  name?: string;
  label?: string;
  reloadOnChangeName?: string;
}

export const OmnijusFiltroTipoAcao = (props: OmnijusFiltroTipoAcaoProps) => {
  const formikContext = useFormikContext<any>();
  const { setFieldValue } = formikContext;
  const isFirstRun = useRef(true);
  const name = props.name || "IdClasseProcesso";
  const idRito =
    formikContext.values[props.reloadOnChangeName || filtroRitoName];
  const [promiseOpcoesTipoAcao, setPromiseOpcoesTipoAcao] =
    useState<Promise<FiltroClasse[] | undefined>>();

  useEffect(() => {
    isFirstRun.current || setFieldValue(name, undefined);
    isFirstRun.current = false;
    setPromiseOpcoesTipoAcao(ProcessoFiltroService.listarClasse(idRito));
  }, [idRito, props.reloadOnChangeName, name, setFieldValue]);

  return (
    <Loading promise={promiseOpcoesTipoAcao}>
      {(opcoesTipoAcao) => (
        <OmnijusSelectField
          name={name}
          label={props.hideLabel ? undefined : props.label || "Tipo de ação"}
          options={opcoesTipoAcao
            ?.map((o) => ({
              value: o.id.toString(),
              label: o.value.toString(),
            }))
            .sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )}
        />
      )}
    </Loading>
  );
};
