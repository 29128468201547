import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { ActionFeedback, ButtonPrimary, Loading, OmnijusCard } from "@omnijus/common";
import { Form } from "formik";
import { PerfilService } from "lib/http/configuracoes/perfil/perfil-service";
import { AdicionarUsuarioPerfilCommand, UsuarioPerfilViewModel } from "lib/http/configuracoes/perfil/perfil-viewmodel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { ButtonOutlined } from "shared/buttons/button-outlined/button-outlined";
import { OmnijusCampoUsuario } from "shared/form/custom-fields/omnijus-campo-usuario";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { object as YupObject, string as YupString } from "yup";
import styles from "./perfil.module.scss";

export const addUsuarioCommandValidation = YupObject().shape({
    idUsuario: YupString().required("Selecione um usuário"),
});

export const UsuarioPerfil = ({ idPerfil }: { idPerfil: string }) => {
    const [usuariosPromise, setUsuariosPromise] = useState<Promise<UsuarioPerfilViewModel[] | undefined>>();

    const getUsuarios = useCallback(async () => {
        setUsuariosPromise(PerfilService.listarUsuarios(idPerfil));
    }, [idPerfil, setUsuariosPromise]);

    const removeUsuario = useCallback(
        async (idPerfilUsuario: string) => {
            const resposta = await ActionFeedback.confirm("Deseja remover o usuário do perfil?");

            if (!resposta) {
                return;
            }

            await ActionFeedback.processing({
                title: "Removendo usuário do perfil...",
                execution: PerfilService.removerUsuario(idPerfil, idPerfilUsuario),
            });

            getUsuarios();
        },
        [idPerfil, getUsuarios]
    );

    const columns = useMemo<IDataTableColumn<UsuarioPerfilViewModel>[]>(
        () => [
            {
                name: "Nome",
                selector: "nome",
                id: "nome",
                maxWidth: "30%",
                sortable: true,
                wrap: false,
            },
            {
                name: "Cpf",
                maxWidth: "20%",
                selector: "cpf",
                sortable: true,
                id: "cpf",
                wrap: false,
            },
            {
                name: "E-mail",
                maxWidth: "30%",
                selector: "email",
                sortable: true,
                id: "email",
                wrap: false,
            },
            {
                name: "Status",
                maxWidth: "20px",
                selector: "ativo",
                sortable: true,
                id: "ativo",
                cell: (row) => (row.ativo ? "Ativo" : "Inativo"),
            },
            {
                name: "excluir",
                maxWidth: "10px",
                sortable: false,
                id: "excluir",
                cell: (row) => (
                    <span onClick={() => removeUsuario(row.id)}>
                        <Icon path={mdiTrashCan} size={1} />
                    </span>
                ),
            },
        ],
        [removeUsuario]
    );

    useEffect(() => {
        getUsuarios();
    }, [getUsuarios]);

    const addUsuario = useCallback(async () => {
        const form = (
            <Form className={styles.formAddUsuario}>
                <div className={styles.fields}>
                    <OmnijusCampoUsuario name="idUsuario" label="Usuário" />
                </div>
                <div className={styles.buttons}>
                    <ButtonPrimary type="submit">Adicionar</ButtonPrimary>
                </div>
            </Form>
        );

        const values = await showModalForm<AdicionarUsuarioPerfilCommand>(
            "Adicionar Usuário",
            { idUsuario: "" },
            form,
            "600px",
            addUsuarioCommandValidation
        );

        if (!values) {
            return;
        }

        await ActionFeedback.processing({
            title: "Adicionando o usuário ao perfil",
            execution: PerfilService.adicionarUsuario(idPerfil, {
                idUsuario: values.idUsuario,
            }),
        });

        getUsuarios();
    }, [getUsuarios, idPerfil]);

    return (
        <OmnijusCard
            body={
                <Loading promise={usuariosPromise}>
                    {(usuarios) => (
                        <DataTable
                            title="Usuários do perfil"
                            actions={[
                                <ButtonOutlined key="bt-adicionar" onClick={addUsuario}>
                                    Adicionar
                                </ButtonOutlined>,
                            ]}
                            highlightOnHover
                            pagination
                            noDataComponent={<p>Nenhum Usuário no perfil</p>}
                            data={usuarios || []}
                            columns={columns}
                        />
                    )}
                </Loading>
            }
        />
    );
};
