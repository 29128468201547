import { ActionFeedback, Loading, OmnijusCard, SelectObject } from "@omnijus/common";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as DownloadIcon } from "assets/images/download-icon.svg";
import { ReactComponent as DocumentIcon } from "assets/images/document-icon.svg";
import { Movimento } from 'lib/http/agendamento/models/movimento';
import { ProcessoService } from 'lib/http/processo/processo-service';

export const CardMovimentoGerador = ({
    idProcesso,
    idProcessoMovimento,
    handleDocumentoSelecionado,
}: {
    idProcesso: string;
    idProcessoMovimento?: string;
    handleDocumentoSelecionado: (documentoSelecionado: SelectObject | undefined) => void;
}) => {
    const [promise, setPromise] = useState<Promise<Movimento | undefined>>();

    useEffect(() => {
        if (idProcessoMovimento) {
            setPromise(ProcessoService.obterMovimento(idProcesso, idProcessoMovimento));
        } else {
            setPromise(Promise.resolve(undefined));
        }
    }, [idProcesso, idProcessoMovimento]);

    return (
        <Loading promise={promise}>
            {(movimentoGerador) => (
                <OmnijusCard
                    header={<h2>Movimento Gerador</h2>}
                    body={
                        <DataTable
                            pagination={false}
                            noDataComponent={<p>Não possui movimento vinculado</p>}
                            data={(movimentoGerador && ([{ ...movimentoGerador }] as Movimento[])) || []}
                            columns={[
                                {
                                    name: "Data",
                                    selector: (row) => row.data && new Date(row.data),
                                    format: (row) => row.data && format(new Date(row.data), "dd/MM/yyyy HH:mm"),
                                },
                                {
                                    name: "Fase",
                                    selector: (row) => row.fase,
                                    wrap: true,
                                },
                                {
                                    name: "Movimentação",
                                    selector: (row) => row.movimentacao,
                                    wrap: true,
                                },
                                {
                                    button: true,
                                    name: "Documento(s)",
                                    selector: (row) =>
                                        row.documentos?.length > 1 ? (
                                            <DownloadIcon
                                                title="Clique para baixar os documentos do movimento"
                                                style={{ cursor: "pointer" }}
                                                onClick={async () => {
                                                    await ActionFeedback.processing({
                                                        title: "Processando...",
                                                        execution: ProcessoService.downloadArquivosMovimento(
                                                            idProcesso,
                                                            row.idProcessoMovimento
                                                        ),
                                                        onError: async (error: any) => {
                                                            return await ActionFeedback.error({
                                                                icon: "error",
                                                                html: error?.json?.erros
                                                                    ? error.json.erros.join("<br />")
                                                                    : error?.json?.title
                                                                    ? error?.json?.title
                                                                    : "Não foi possível realizar o download dos documentos",
                                                            });
                                                        },
                                                    });
                                                }}
                                            />
                                        ) : row.documentos?.length === 1 ? (
                                            <DocumentIcon
                                                title={row.documentos[0].nome}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleDocumentoSelecionado({
                                                        label: `${row.documentos[0].tipo}: ${row.documentos[0].nome}`,
                                                        value: row.documentos[0].path,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            "-"
                                        ),
                                    width: "7rem",
                                },
                            ]}
                        />
                    }
                />
            )}
        </Loading>
    );
};
