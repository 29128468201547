import { Loading, OmnijusSelectField, SelectObject } from "@omnijus/common";
import { PerfilService } from "lib/http/configuracoes/perfil/perfil-service";
import React, { useEffect, useState } from "react";

interface OmnijusCampoPerfilProps {
    hideLabel?: boolean;

    name?: string;
    label?: string;
    isMulti?: boolean;
    value?: any;
}

export const OmnijusFiltroPerfil = (props: OmnijusCampoPerfilProps) => {
    const name = props.name || "idPerfil";
    const [promise, setPromise] = useState<Promise<SelectObject[] | undefined>>();

    useEffect(() => {
        setPromise(
            PerfilService.listar().then((res) => {
                return res?.results.map((item) => ({ value: item.id, label: item.descricao })) || [];
            })
        );
    }, []);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Perfil"}
                    isMulti={props.isMulti}
                    options={opcoes}
                />
            )}
        </Loading>
    );
};
