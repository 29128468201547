import { EscritorioRequestApi } from "../escritorio-request-api";
import { FiltroArea } from "../filtros/models/filtro-area";
import { FiltroForo } from "../filtros/models/filtro-foro";
import { FiltroOrgao } from "../filtros/models/filtro-orgao";
import { FiltroSecao } from "../filtros/models/filtro-secao";

const urlBaseConsulta =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/Dominio/v1/Consulta";

export const DominioService = {
  consultarArea: async (id?: number) => {
    const url = new URL(`${urlBaseConsulta}/Area`);

    if (id !== undefined) {
      url.pathname = url.pathname.concat(`/${id}`);
    }

    return await EscritorioRequestApi.get<Array<FiltroArea>>(url.toString());
  },
  consultarOrgao: async (params?: {
    id?: string;
    idsAreas?: Array<number>;
    uf?: string;
  }) => {
    const url = new URL(`${urlBaseConsulta}/Orgao`);

    if (params?.id !== undefined) {
      url.pathname = url.pathname.concat(`/${params.id}`);
    }

    if (params?.idsAreas !== undefined && params?.idsAreas.length > 0) {
      params.idsAreas.forEach((id) => {
        url.searchParams.append("idsAreas", id.toString());
      });
    }

    if (params?.uf !== undefined) {
      url.searchParams.set("uf", params.uf);
    }

    return await EscritorioRequestApi.get<Array<FiltroOrgao>>(url.toString());
  },
  consultarOrgaoUnidade: async (params?: {
    id?: string;
    idsOrgaos?: Array<string>;
    nomeComarca?: string;
  }) => {
    const url = new URL(`${urlBaseConsulta}/OrgaoUnidade`);

    if (params?.id !== undefined) {
      url.pathname = url.pathname.concat(`/${params.id}`);
    }

    if (params?.idsOrgaos !== undefined && params?.idsOrgaos.length > 0) {
      params.idsOrgaos.forEach((id) => {
        url.searchParams.append("idsOrgaos", id);
      });
    }

    if (params?.nomeComarca !== undefined) {
      url.searchParams.set("nomeComarca", params.nomeComarca);
    }

    return await EscritorioRequestApi.get<Array<FiltroForo>>(url.toString());
  },
  consultarOrgaoUnidadeSecao: async (params?: {
    id?: string;
    idsOrgaosUnidades?: Array<string>;
  }) => {
    const url = new URL(`${urlBaseConsulta}/OrgaoUnidadeSecao`);

    if (params?.id !== undefined) {
      url.pathname = url.pathname.concat(`/${params.id}`);
    }

    return await EscritorioRequestApi.post<Array<FiltroSecao>>(
      url.toString(),
      params
    );
  },
  listarStatusProcesso: async () => {
    const url = new URL(`${urlBaseConsulta}/ListarStatusProcesso`);

    return await EscritorioRequestApi.get<{ id: number; value: string }[]>(
      url.toString(),
      (res, json) => ({
        res: res,
        json: json,
      })
    );
  },
};
