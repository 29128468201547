import React, { useEffect, useState } from "react";
import styles from "./listar-validacoes.module.scss";
import Modal from "react-modal";
import { CardInfoSolicitacaoPeticionamento } from "./card-info-solicitacao-peticionamento";
import { Form, Formik } from "formik";
import {
  ActionFeedback,
  ButtonSecondary,
  ButtonPrimary,
  OmnijusCard,
  Loading,
} from "@omnijus/common";
import {
  listarMotivosReprovacao,
  reprovar,
} from "lib/http/peticionamento/peticionamento-service";
import { MotivoReprovacaoPeticionamento } from "lib/http/peticionamento/models/motivo-reprovacao-peticionamento";
import { OmnijusCheckboxField } from "shared/form/fields/omnijus-checkbox-field";
import { SolicitacaoPeticionamentoReprovarCommand } from "lib/http/peticionamento/models/reprovar";
import { OmnijusTextAreaField } from "shared/form/fields/omnijus-textarea-field";

interface ModalReprovarProps {
  idSolicitacaoPeticionamento: string;
  nomeCliente: string | null;
  numeroProcesso: string | null;
  statusSolicitacaoPeticionamento: string | null;
  idProvidencia: number;
  descricaoProvidencia?: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const ModalReprovar = (props: ModalReprovarProps) => {
  const [promise, setPromise] =
    useState<Promise<MotivoReprovacaoPeticionamento[] | undefined>>();

  useEffect(() => {
    props.isModalOpen &&
      setPromise(listarMotivosReprovacao(props.idProvidencia));
  }, [props.isModalOpen, props.idProvidencia]);

  return (
    <Modal
      style={{
        content: {
          width: "60vw",
          margin: "auto",
        },
      }}
      isOpen={props.isModalOpen}
      onRequestClose={props.onCloseModal}
      shouldCloseOnEsc
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <h2>Reprovar Petição</h2>

      <CardInfoSolicitacaoPeticionamento {...props} modal="2Colunas" />

      <Formik
        initialValues={{
          motivosReprovacao: [],
          observacao: "",
        }}
        onSubmit={async (values) => {
          const command: SolicitacaoPeticionamentoReprovarCommand = {
            idSolicitacaoPeticionamento: props.idSolicitacaoPeticionamento,
            motivosReprovacao: values.motivosReprovacao as number[],
            observacao: values.observacao ?? undefined,
          };

          if (!command.motivosReprovacao.flatMap((x) => x).some((x) => x)) {
            await ActionFeedback.error({
              text: "É necessário selecionar pelo menos um motivo",
              icon: "warning",
            });

            return;
          }

          await ActionFeedback.processing({
            title: "Processando...",
            execution: reprovar(command),
            onError: async (error: any) =>
              await ActionFeedback.error({
                icon: "error",
                html: error?.json?.erros
                  ? error.json.erros.join("<br />")
                  : error?.json?.title
                  ? error?.json?.title
                  : "Não foi possível realizar a reprovação",
              }),
          });

          await ActionFeedback.info({
            text: "Reprovação realizada com sucesso",
          });

          props.onCloseModal();
          window.location.reload();
        }}
      >
        <Form className={styles.marginTop}>
          <Loading promise={promise}>
            {(motivos) => (
              <>
                <OmnijusCard
                  header={
                    <h4>Selecione abaixo os motivos para a reprovação</h4>
                  }
                  body={
                    <OmnijusCheckboxField
                      options={
                        motivos?.map((m) => {
                          return {
                            label: m.motivo,
                            value: m.id.toString(),
                          };
                        }) || []
                      }
                      name="motivosReprovacao"
                    />
                  }
                />
                <OmnijusCard
                  header={<h4>Observação</h4>}
                  body={<OmnijusTextAreaField name="observacao" />}
                />
              </>
            )}
          </Loading>
          <div className={styles.alignRight}>
            <ButtonPrimary
              className={`${styles.marginRight} ${styles.marginTop}`}
              type="submit"
            >
              Salvar
            </ButtonPrimary>
            <ButtonSecondary
              className={styles.marginTop}
              onClick={props.onCloseModal}
            >
              Fechar
            </ButtonSecondary>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
