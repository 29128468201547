import { EscritorioRequestApi } from "../escritorio-request-api";
import { Providencia } from "./models/providencia";

const providenciaUrl = process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/v1/Providencia";

export const ProvidenciaService = {
    listarPorPerfil: async () => {
        const url = new URL(`${providenciaUrl}/ListarPorPerfil`);
        return await EscritorioRequestApi.get<Providencia[]>(url.toString());
    },
    listar: async () => {
        const url = new URL(`${providenciaUrl}/Listar`);
        return await EscritorioRequestApi.get<Providencia[]>(url.toString());
    },
};
