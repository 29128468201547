import { EscritorioRequestApi } from "../escritorio-request-api";
import { AcordoProcesso } from "./models/acordo-processo";
import { DetalheProcesso } from "./models/detalhe-processo";
import { DocumentoAutomatizado } from "./models/documento-automatizado";
import { ExecucaoAtividade } from "./models/execucao-atividade";
import { MovimentoProcesso } from "./models/movimento-processo";

const processoDetalheUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL +
  "/api/Processo/v1/ProcessoDetalhe";

export const ProcessoDetalheService = {
  getDetalhes: async (id: string) => {
    let url = `${processoDetalheUrl}/${id}`;
    return await EscritorioRequestApi.get<DetalheProcesso>(url);
  },
  getMovimentos: async (id: string, apenasComDocumentos: boolean) => {
    let url = `${processoDetalheUrl}/${id}/Movimentos?apenasComDocumentos=${apenasComDocumentos}`;
    return await EscritorioRequestApi.get<MovimentoProcesso[]>(url);
  },
  downloadMovimento: async (
    idProcesso: string,
    idMovimento: string,
    filename: string
  ) => {
    const url = `${processoDetalheUrl}/${idProcesso}/Movimento/${idMovimento}/Download`;
    return await EscritorioRequestApi.download(url, filename);
  },
  getAtividades: async (id: string) => {
    let url = `${processoDetalheUrl}/${id}/ExecucoesAtividade`;
    return await EscritorioRequestApi.get<ExecucaoAtividade[]>(url);
  },
  getTentativasAcordo: async (id: string) => {
    let url = `${processoDetalheUrl}/${id}/Acordos`;
    let res = await EscritorioRequestApi.get<AcordoProcesso[]>(url);
    if (res) {
      res.forEach((acordo) => {
        // converte data em string da resposta para Date
        acordo.dataValidadeInicio =
          acordo.dataValidadeInicio && new Date(acordo.dataValidadeInicio);
        acordo.dataValidadeFim =
          acordo.dataValidadeFim && new Date(acordo.dataValidadeFim);
      });
    }
    return res;
  },
  getDocumentosAutomatizados: async (id: string) => {
    const url = `${processoDetalheUrl}/${id}/ListarDocumentosAutomatizados`;
    const res = await EscritorioRequestApi.get<DocumentoAutomatizado[]>(url);
    res?.forEach(
      (doc) => (doc.dataGeracaoDocumento = new Date(doc.dataGeracaoDocumento))
    );
    return res;
  },
};
