import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { SolicitacaoPeticionamentoDocumentoViewModel } from "lib/http/peticionamento/models/solicitacao-peticionamento-documento";
import {
    listarDocumentos,
    removerDocumento,
    substituirDocumento,
} from "lib/http/peticionamento/peticionamento-service";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import styles from "./listar-validacoes.module.scss";
import { OmnijusFileField } from "shared/form/fields/omnijus-file-field";
import { Form, Formik } from "formik";
import { format } from "date-fns";
import { IdStatusPeticionamento } from "lib/http/peticionamento/models/id-status-peticionamento";
import { ActionFeedback, ButtonPrimary, Loading, OmnijusCard, SelectObject } from "@omnijus/common";

interface NovoDocumento {
    documento: SolicitacaoPeticionamentoDocumentoViewModel;
    novoDocumento: File | null;
}

export const ListaDocumentosPeticionamento = ({
    idSolicitacaoPeticionamento,
    idStatusSolicitacaoPeticionamento,
    handleDocumentoSelecionado,
    documentoSelecionado,
}: {
    idSolicitacaoPeticionamento: string;
    idStatusSolicitacaoPeticionamento: number;
    idProcesso: string;
    handleDocumentoSelecionado: (documentoSelecionado: SelectObject | undefined) => void;
    documentoSelecionado?: SelectObject;
}) => {
    const [promiseDocumentos, setPromiseDocumentos] =
        useState<Promise<SolicitacaoPeticionamentoDocumentoViewModel[] | undefined>>();

    useEffect(() => {
        setPromiseDocumentos(listarDocumentos(idSolicitacaoPeticionamento));
    }, [idSolicitacaoPeticionamento]);

    return (
        <div className={styles.marginTop}>
            <Loading promise={promiseDocumentos}>
                {(documentos) => {
                    if (documentos && documentos[0] && !documentoSelecionado) {
                        handleDocumentoSelecionado({
                            value: documentos[0].pathDocumento,
                            label: `${documentos[0].tipoDocumento}: ${documentos[0].tituloDocumento}`,
                        });
                    }

                    return (
                        <OmnijusCard
                            header={<h2>Documentos a serem Protocolados</h2>}
                            body={
                                <DataTable
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    data={documentos || []}
                                    columns={[
                                        {
                                            name: "Título",
                                            selector: (documento) => (
                                                <span
                                                    title="Clique para visualizar o documento"
                                                    className={styles.hyperlink}
                                                    onClick={() => {
                                                        handleDocumentoSelecionado({
                                                            value: documento.pathDocumento,
                                                            label: `${documento.tipoDocumento}: ${documento.tituloDocumento}`,
                                                        });
                                                    }}
                                                >
                                                    {documento.tituloDocumento}.pdf
                                                </span>
                                            ),
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Data/hora atualização",
                                            selector: (documento) =>
                                                documento.dataHoraAtualizacao &&
                                                format(new Date(documento.dataHoraAtualizacao), "dd/MM/yyyy HH:mm:ss"),
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Substituir",
                                            omit: true,
                                            selector: (documento) => {
                                                const initialValues: NovoDocumento = {
                                                    documento: documento,
                                                    novoDocumento: null,
                                                };

                                                return (
                                                    (idStatusSolicitacaoPeticionamento ===
                                                        IdStatusPeticionamento.PendenteAprovacao ||
                                                        idStatusSolicitacaoPeticionamento ===
                                                            IdStatusPeticionamento.ReprovadoEscritorio) &&
                                                    documento.substituirProtocolo && (
                                                        <Formik
                                                            initialValues={initialValues}
                                                            onSubmit={async (values) => {
                                                                if (!values.novoDocumento) {
                                                                    await ActionFeedback.info({
                                                                        text: "Selecione um arquivo",
                                                                    });

                                                                    return;
                                                                }

                                                                await ActionFeedback.processing({
                                                                    title: "Processando...",
                                                                    execution: substituirDocumento(
                                                                        documento.idSolicitacaoPeticionamentoDocumento,
                                                                        values.novoDocumento
                                                                    ),
                                                                    onError: async (error: any) =>
                                                                        await ActionFeedback.error({
                                                                            icon: "error",
                                                                            html: error?.json?.erros
                                                                                ? error.json.erros.join("<br />")
                                                                                : error?.json?.title
                                                                                ? error?.json?.title
                                                                                : "",
                                                                        }),
                                                                });

                                                                await ActionFeedback.info({
                                                                    text: "Documento substituído!",
                                                                });

                                                                window.location.reload();
                                                            }}
                                                        >
                                                            {(formik) => (
                                                                <Form>
                                                                    <OmnijusFileField
                                                                        accept="application/pdf"
                                                                        maxSizeMB={5}
                                                                        name="novoDocumento"
                                                                    />
                                                                    {formik.values.novoDocumento && (
                                                                        <ButtonPrimary
                                                                            className="btn-primary-small"
                                                                            type="submit"
                                                                        >
                                                                            Enviar
                                                                        </ButtonPrimary>
                                                                    )}
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    )
                                                );
                                            },
                                            wrap: true,
                                        },
                                        {
                                            name: "Remover",
                                            button: true,
                                            selector: (documento) => (
                                                <button
                                                    className={styles.deleteButton}
                                                    disabled={
                                                        !documento.removerProtocolo ||
                                                        idStatusSolicitacaoPeticionamento !==
                                                            IdStatusPeticionamento.PendenteAprovacao
                                                    }
                                                    title="Remover documento"
                                                    onClick={async () => {
                                                        const exclusaoConfirmada = await ActionFeedback.confirm(
                                                            `Remover documento`,
                                                            "Sim",
                                                            "Cancelar",
                                                            `Confirma a exclusão do documento ${documento.tituloDocumento}?`,
                                                            "question"
                                                        );

                                                        if (exclusaoConfirmada) {
                                                            await ActionFeedback.processing({
                                                                title: "Excluindo documento...",
                                                                execution: removerDocumento(
                                                                    documento.idSolicitacaoPeticionamentoDocumento
                                                                ),
                                                                onError: async (error: any) =>
                                                                    await ActionFeedback.error({
                                                                        title: error,
                                                                    }),
                                                            });

                                                            await ActionFeedback.info({
                                                                text: "Documento excluído!",
                                                            });

                                                            setPromiseDocumentos(
                                                                listarDocumentos(idSolicitacaoPeticionamento)
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon className={styles.deleteIcon} />
                                                </button>
                                            ),
                                        },
                                    ]}
                                />
                            }
                        />
                    );
                }}
            </Loading>
        </div>
    );
};
