import { Loading } from "@omnijus/common";
import React from "react";
import styles from "./filtros-aplicados.module.scss";

interface FiltrosAplicadosProps {
    tags: Array<FiltroAplicado>;
}

export interface FiltroAplicado {
    label: string;
    value?: string;
    valuePromise?: Promise<string>;
    onRemove?: (label: string) => void;
}

export const FiltrosAplicados = (props: FiltrosAplicadosProps) => {
    return (
        <div className={styles.filtrosAplicados}>
            {props.tags?.length > 0 && <span className={styles.prefix}>Filtros aplicados:</span>}
            {props.tags.map((t) => (
                <div key={t.label + t.value} className={styles.tag}>
                    <span>{t.label}</span>
                    {(t.value || t.valuePromise) && (
                        <>
                            <span>:</span>
                            <span className={styles.value}>
                                {t.valuePromise ? (
                                    <Loading inline promise={t.valuePromise}>
                                        {(value) => <>{value ? value : ""}</>}
                                    </Loading>
                                ) : (
                                    t.value
                                )}
                            </span>
                        </>
                    )}
                    {t.onRemove && (
                        <span className={styles.botaoFechar} onClick={() => t.onRemove && t.onRemove(t.label)}>
                            x
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};
