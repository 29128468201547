import { OmnijusCard } from "@omnijus/common";
import React from "react";
import styles from "./listar-validacoes.module.scss";

interface CardInfoSolicitacaoPeticionamentoProps {
  idSolicitacaoPeticionamento?: string;
  nomeCliente?: string | null;
  numeroProcesso?: string | null;
  dataPrevistaPeticionamento?: string | null;
  statusSolicitacaoPeticionamento?: string | null;
  modal: "2Colunas" | "4Colunas";
  descricaoProvidencia?: string;
}

export const CardInfoSolicitacaoPeticionamento = (
  props: CardInfoSolicitacaoPeticionamentoProps
) => {
  return (
    <div className={styles.marginTop}>
      <OmnijusCard
        body={
          <div
            className={
              props.modal === "4Colunas"
                ? styles.modalDocumentosInfo
                : styles.modalDataPrevistaInfo
            }
          >
            <p>
              <strong>Cliente:</strong> {props.nomeCliente}
            </p>

            {props.dataPrevistaPeticionamento && (
              <p>
                <strong>Data prevista peticionamento:</strong>{" "}
                {props.dataPrevistaPeticionamento}
              </p>
            )}
            <p>
              <strong>Status:</strong> {props.statusSolicitacaoPeticionamento}
            </p>
            <p>
              <strong>Processo:</strong> {props.numeroProcesso}
            </p>
            {props.descricaoProvidencia && (
              <p>
                <strong>Providência:</strong> {props.descricaoProvidencia}
              </p>
            )}
          </div>
        }
      />
    </div>
  );
};
