import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../client-id";
import { EscritorioViewModel } from "./models/escritorio-viewmodel";

const escritorioUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL + "/api/v1/escritorio";

const request = new RequestApi(CLIENT_ID);

export const EscritorioService = {
  listar() {
    return request.get<EscritorioViewModel[]>(escritorioUrl);
  },
};
