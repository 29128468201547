import { ErroSessao, Loading } from "@omnijus/common";
import {
  getSessionIdEscritorio,
  setSessionIdEscritorio,
} from "lib/http/escritorio-request-api";
import { EscritorioService } from "lib/http/escritorio/escritorio-service";
import { EscritorioViewModel } from "lib/http/escritorio/models/escritorio-viewmodel";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ListaEscritorios } from "./lista-escritorios";

export interface EscritorioContextType {
  escritorios: EscritorioViewModel[];
  escritorio?: EscritorioViewModel;
}

export const EscritorioContext = React.createContext<EscritorioContextType>({
  escritorio: undefined,
  escritorios: [],
});

export const EscritorioProvider = (props: PropsWithChildren<{}>) => {
  const [promise, setPromise] =
    useState<Promise<EscritorioViewModel[] | undefined>>();

  useEffect(() => {
    setPromise(
      EscritorioService.listar().catch((e) => {
        return undefined;
      })
    );
  }, [setPromise]);

  return (
    <Loading promise={promise}>
      {(Escritorios) => {
        if (!Escritorios) {
          return (
            <ErroSessao message="Erro ao buscar os Escritórios do usuário" />
          );
        }

        if (Escritorios.length === 0) {
          return (
            <ErroSessao message="Você não possui acesso a nenhum escritório ativo!" />
          );
        }

        return (
          <WrapperEscritorios escritorios={Escritorios}>
            {props.children}
          </WrapperEscritorios>
        );
      }}
    </Loading>
  );
};

const WrapperEscritorios = ({
  escritorios,
  children,
}: PropsWithChildren<{ escritorios: EscritorioViewModel[] }>) => {
  const [idEscritorio, setIdEscritorio] = useState<string | null>(
    getSessionIdEscritorio()
  );

  const changeEscritorio = useCallback(
    (idEscritorio: string) => {
      setSessionIdEscritorio(idEscritorio);
      setIdEscritorio(idEscritorio);
    },
    [setIdEscritorio]
  );

  const EscritorioSelecionado = useMemo(() => {
    if (idEscritorio && escritorios.find((c) => c.id === idEscritorio)) {
      return escritorios.find((c) => c.id === idEscritorio);
    }

    if (escritorios.length === 1) {
      changeEscritorio(escritorios[0].id);
      return escritorios[0];
    }

    return null;
  }, [escritorios, idEscritorio, changeEscritorio]);

  return EscritorioSelecionado ? (
    <EscritorioContext.Provider
      value={{ escritorio: EscritorioSelecionado, escritorios: escritorios }}
    >
      {children}
    </EscritorioContext.Provider>
  ) : (
    <ListaEscritorios
      escritorios={escritorios}
      setIdEscritorio={(idEscritorio) => {
        changeEscritorio(idEscritorio);
      }}
    />
  );
};

export const useEscritorio = () => useContext(EscritorioContext);
