import { EscritorioRequestApi } from "../escritorio-request-api";
import { StatusSolicitacaoPeticionamento } from "./models/status-solicitacao-peticionamento";

const solicitacaoPeticionamentoUrl =
  process.env.REACT_APP_BFF_ESCRITORIO_API_URL +
  "/api/Processo/v1/SolicitacaoPeticionamento";

export const listarStatusPeticionamento = () => {
  const url = `${solicitacaoPeticionamentoUrl}/ListarStatus`;
  return EscritorioRequestApi.get<StatusSolicitacaoPeticionamento[]>(url);
};
